<template>
    <div>
        <!-- 底部信息 公司信息-->
        <div style="background-color: #F8F8F8;">
            <div class="footer-box animate__animated animate__fadeInUpBig">
                <div class="footer-content">
                    <div class="footer-introduce">
                        <div v-for="(item, index) in navigationList" :key="index" @click="topRunterun">
                            <div class="footer-introduce-item" v-if="index != 0">
                                <router-link class="footer-introduce-title" :to="item.path" >
                                    {{ item.name }}
                                </router-link>
                                <div v-if="item.children" style="display: flex;flex-direction: column;">
                                    <router-link class="footer-introduce-name" :to="Citem.path"
                                        v-for="(Citem, Cindex) in item.children" :key="Cindex">
                                        {{ Citem.name }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-shinformation">
                        <div class="footer-shinformation-content">
                            <div class="footer-shinformation-item">
                                <img class="footer-shinformation-img" src="@/assets/imgs/shfooterddh.png" />
                                <span class="footer-shinformation-text">{{ (systemInfo?.telephone) || '17620428222' }}
                                </span>
                            </div>
                            <div class="footer-shinformation-item">
                                <img class="footer-shinformation-img" src="@/assets/imgs/shfooteremile.png" />
                                <span class="footer-shinformation-text">
                                    {{ (systemInfo?.email) || 'changhao940@sina.com' }}</span>
                            </div>
                            <div class="footer-shinformation-item">
                                <img class="footer-shinformation-img" src="@/assets/imgs/shfooteraddress.png" />
                                <span class="footer-shinformation-text">{{ (systemInfo?.address) ||
                                    '贵州省贵阳市云岩区市西河街道瑞金中路77号百灵时尚天地第19楼08号' }}</span>
                            </div>
                        </div>
                        <div class="footer-shinformation-content"
                            style="border-left:  1px solid rgba(0, 0, 0, 0.2);padding-left: 40px;">
                            <div class="footer-shinformation-item">
                                <span class="footer-shinformation-text">© 版权所有{{ (systemInfo?.copyRight) || '贵阳晶戈展珠宝有限公司' }}</span>
                            </div>
                            <div class="footer-shinformation-item">
                                <span class="footer-shinformation-text">
                                    {{ (systemInfo?.internetContentProvider) || '黔ICP备2023008162号-1' }}</span>
                            </div>
                            <a :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + (systemInfo?.networkSecure)"
                                v-if="systemInfo?.networkSecure">
                                <div class="footer-shinformation-item1" style="cursor: pointer;">
                                    <img class="footer-shinformation-img" src="@/assets/imgs/shfooterjctb.png" />
                                    <span class="footer-shinformation-text"> 贵公网安备{{ systemInfo?.networkSecure }}</span>
                                </div>
                            </a>
                        </div>
                        <div class="footer-shinformation-code">
                            <img class="footer-shinformatio-codeimg" v-if="systemInfo?.wechatOfficialUrl"
                                :src="filePathUrl + systemInfo?.wechatOfficialUrl" />
                            <img class="footer-shinformatio-codeimg" v-else src="@/assets/imgs/sh_code.png" />
                            <span class="footer-shinformation-codetext">二维码</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import storage from '@/utils/storage'
export default {
    props: {
        msg: String
    },
    data() {
        return {
            filePathUrl: process.env.VUE_APP_BASE_API,
            systemInfo: storage.get('systemInfo'), // 系统基本信息
            navigationList: storage.get('navigationList') // 系统导航信息
        };
    },
    methods: {
        topRunterun() {
            // let timer = setInterval(() => {
            //     // 获取当前滚动高度
            //     const top = document.body.scrollTop || document.documentElement.scrollTop;
            //     // 设置滚动速度
            //     const speed = top / 4;
            //     if (document.body.scrollTop !== 0) {
            //         document.body.scrollTop -= speed;
            //     } else {
            //         document.documentElement.scrollTop -= speed;
            //     }
            //     if (top === 0) {
            //         clearInterval(timer)
            //     }
            // }, 16) // 设置触发频率，数值越小动画越流畅，但是事件触发次数越多
            scrollTo(0,0);
        }
    },
    // 初始化载入
    mounted() {
    }
}
</script>
<style scoped>
/* 底部  start*/

.footer-box {
    width: 100%;
    background-image: url('@/assets/imgs/shfooterbjimg.png');
    /* margin-top: 30px; */
    background-size: cover;
    background-repeat: no-repeat;
}

.footer-content {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
}

.footer-introduce {
    display: flex;
    padding: 30px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    justify-content: space-between;
}

.footer-introduce-item {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
}

.footer-introduce-title {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    text-align: left;
}
.footer-introduce-title:hover {
    color: #0261B8;
}
.footer-introduce-name {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-top: 20px;
    text-align: left;
}

.footer-introduce-name:hover {
    color: #0261B8;
}

.footer-shinformation {
    display: flex;
    align-items: center;
    padding: 30px 0;
    justify-content: space-between;
}

.footer-shinformation-content {
    display: flex;
    flex-direction: column;

}

.footer-shinformation-right {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
}

.footer-shinformation-item,
.footer-shinformation-item1 {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    text-align: left;

}

.footer-shinformation-item:last-child {
    margin-bottom: 0px;
}

.footer-shinformation-item1 :hover {
    color: #0261B8;

}

.footer-shinformation-img {
    width: 22px;
    height: 22px;
    margin-right: 15px;
}

.footer-shinformation-text {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
}

.footer-shinformation-code {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.footer-shinformatio-codeimg {
    width: 102px;
    height: 102px;

}

.footer-shinformation-codetext {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    text-align: center;
    margin-top: 10px;
}

/* 底部  end*/</style>
  