<template>
    <div>
        <!-- 轮播 -->
        <shBanner :pagesName="'contactCode'" :bannerHeight="'476px'"></shBanner>
        <!-- 路径 -->
        <pathRoute :routeTest="routeTest"  @activeClick="activeClick" />
        <!-- 内容 -->
        <recruit v-if="activeIndex == 0"></recruit>
        <contact v-if="activeIndex == 1"></contact>
    </div>
</template>
<script>
import pathRoute from "@/components/pathRoute.vue";
import shBanner from "@/components/sh_banner.vue";
import recruit from "./recruit.vue";
import contact from "./contact.vue";
export default {
    components: {
        pathRoute,
        shBanner,
        recruit,
        contact,
    },
    data() {
        return {
            activeIndex: 0,
            routeTest: [
                { title: "人才招聘", path: '/recruit' },
                { title: "联系我们", path: '/contact' },
            ],
        };
    },
    methods: {
        activeClick(val) {
            this.activeIndex = val.index
        }
    },
    watch: {
        $route: {
            handler(newval) {
                this.activeIndex =Number(newval?.query?.index) 
            },
            // 深度观察监听
            deep: true
        }
    },

    // 初始化载入
    mounted() {
        // 初始化
        this.activeIndex = Number(this.$route?.query?.index) 
    },
};
</script>
<style lang="scss" scoped></style>
  