//  localStorage
export default {
    // 存储
    set:function(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    // 取出数据
    get:function(key) {
        return JSON.parse(localStorage.getItem(key));
    },
    // 删除数据
    remove:function(key) {
        localStorage.removeItem(key);
    }

};
