/**
 * 全局配置文件
 */
export default {
    title: "",
    logo: "",
    tokenTime: 6000,
    baseUrl: process.env.VUE_APP_BASE_API + process.env.VUE_APP_API_URL,
    uploadUrl: process.env.VUE_APP_BASE_API+'/admin/file/upload',
    downUrl:process.env.VUE_APP_BASE_API+'/admin/file/download?fileKey=',
}
