<template>
  <div>
    <!-- 轮播 -->
    <!-- <shBanner :pagesName="'aboutMyCode'" :bannerHeight="'476px'" ></shBanner> -->
    <!-- 路径 -->
    <!-- <pathRoute :routeTest="routeTest" :activeIndex="'2'"/> -->
    <div class="development-history">
      <div class="head">
        <h2>发展历程</h2>
        <p>development history</p>
      </div>
      <!-- 内容 -->
      <div class="arrow">
        <i class="el-icon-arrow-right arrow-left" @click="leftClick"></i>
        <ul class="content-box">
          <template v-for="(item, index) in viewList">
            <li :key="index">
              <div class="box">
                <div class="year">{{ item.year }}</div>
                <h2>{{ item.groupName }}</h2>
                <p>
                  {{ item.introductory }}
                </p>
                <img class="honor" :src="filePathUrl + item.pictureFile" />
              </div>
              <img src="@/assets/imgs/qiu.gif" class="qiu-img" />
            </li>
          </template>
        </ul>
        <i class="el-icon-arrow-left arrow-right" @click="rigthClick"></i>
      </div>
    </div>
  </div>
</template>
<script>
// import pathRoute from "@/components/pathRoute.vue";
// import shBanner from "@/components/sh_banner.vue";
import { getHistory } from "@/api/HomeView/index";

export default {
  components: {
    // pathRoute,
    // shBanner,
  },
  data() {
    return {
      filePathUrl: process.env.VUE_APP_BASE_API,
      routeTest: [
        { title: "企业简介", path: '/enterpriseIntroduce' },
        { title: "企业文化", path: '/corporateCulture' },
        { title: "发展历程", path: "/developmentHistory" },
        { title: "企业视频", path: '/enterpriseVideo' },
      ],
      viewList: [],
      copyViewList: [],
      count: 1, // 翻页数
      preventRigth: true, //防止count超过对应数量
      preventLeft: false, //防止count超过对应数量
    };
  },
  methods: {
    // 获取新闻信息
    getViewData() {
      getHistory().then((res) => {
        this.copyViewList = res.list;
        this.arrowleft(0);
      });
    },
    // 箭头左数据组装
    arrowleft(num) {
      let data = num * 4;
      if (num == 0) {
        this.viewList.push(this.copyViewList[data]);
        if(!this.copyViewList[data + 1]) return
        this.viewList.push(this.copyViewList[data + 1]);
        if(!this.copyViewList[data + 2]) return
        this.viewList.push(this.copyViewList[data + 2]);
        if(!this.copyViewList[data + 3]) return
        this.viewList.push(this.copyViewList[data + 3]);
        if(!this.copyViewList[data + 4]) return
        this.viewList.push(this.copyViewList[data + 4]);
        console.log(this.viewList);
      } else if (data > this.copyViewList.length - 1) {
        let copyIndex = this.copyViewList.length - 1;
        this.count = 0;
        this.preventRigth = false;
        this.viewList.push(this.copyViewList[copyIndex - 4]);
        this.viewList.push(this.copyViewList[copyIndex - 3]);
        this.viewList.push(this.copyViewList[copyIndex - 2]);
        this.viewList.push(this.copyViewList[copyIndex - 1]);
        this.viewList.push(this.copyViewList[copyIndex]);
      } else {
        data = data + 1;
        this.viewList[4] = this.copyViewList[data];
        this.viewList[3] = this.copyViewList[data - 1];
        this.viewList[2] = this.copyViewList[data - 2];
        this.viewList[1] = this.copyViewList[data - 3];
        this.viewList[0] = this.copyViewList[data - 4];
      }
    },
    // 箭头右数据组装
    arrowRight(num) {
      let data = num * 4 - 1;
      if (data < 4) {
        data = 4;
        this.count = 0;
        this.viewList[4] = this.copyViewList[data];
        this.viewList[3] = this.copyViewList[data - 1];
        this.viewList[2] = this.copyViewList[data - 2];
        this.viewList[1] = this.copyViewList[data - 3];
        this.viewList[0] = this.copyViewList[data - 4];
        this.preventLeft = false;
      } else {
        this.viewList[4] = this.copyViewList[data];
        this.viewList[3] = this.copyViewList[data - 1];
        this.viewList[2] = this.copyViewList[data - 2];
        this.viewList[1] = this.copyViewList[data - 3];
        this.viewList[0] = this.copyViewList[data - 4];
      }
    },
    // 左箭头
    leftClick() {
      if (!this.preventRigth) return;
      this.count++;
      this.viewList = [];
      this.preventLeft = true;
      this.arrowleft(this.count);
    },
    // 右箭头
    rigthClick() {
      if (!this.preventLeft) return;
      this.count--;
      this.viewList = [];
      this.preventRigth = true;
      this.arrowRight(this.count);
    },
    toNewsDetails(item) {
      this.$router.push({
        path: "/newsDetails",
        query: {
          // 路由传参时push和query搭配使用 ，作用时传递参数
          id: item.id,
        },
      });
    },
  },
  // 初始化载入
  mounted() {
    // 初始化
    this.getViewData();
  },
};
</script>
<style lang="scss" scoped>
%year-com {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}

.development-history {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 86px;
  margin-top: 23px;

  .head {
    text-align: center;
    margin-bottom: 73px;

    h2 {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }

  .content-box {
    height: 856px;
    background-size: contain;
    display: flex;

    li {
      list-style: none;
      position: relative;
      .box {
        text-align: left;
        position: absolute;

        .year {
          @extend %year-com;
        }

        h2 {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          display: inline-block;
          background: #ebb344ff;
          margin: 10px 0 13px 0;
          padding: 0 6px;
        }

        p {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          text-indent: 2em;
        }

        .honor {
          height: 100px;
          margin-top: 10px;
        }
        &:nth-child(1n) {
          top: 50%;
        }
      }
      .qiu-img {
        position: absolute;
        height: 80px;
      }

      &:nth-child(1) {
        width: 280px;
        .box {
          margin-left: 81px;
          transform: translateY(-26%);
        }
        .qiu-img {
          left: 15px;
          top: 59%;
          transform: translateY(50px);
        }
      }

      &:nth-child(2) {
        width: 258px;
        .box {
          margin-left: 60px;
          top: 90px;
          h2 {
            background: #da5e4dff;
          }
        }
        .qiu-img {
          left: -7px;
          top: 8%;
        }
      }

      &:nth-child(3) {
        width: 240px;

        .box {
          margin-left: 28px;
          transform: translateY(-18%);
          h2 {
            background: #4ca374ff;
          }
        }
        .qiu-img {
          left: -39px;
          top: 64%;
          transform: translateY(50px);
        }
      }

      &:nth-child(4) {
        width: 260px;
        .box {
          margin-left: 55px;
          top: 108px;
          h2 {
            background: #00599aff;
          }
        }
        .qiu-img {
          left: -11px;
          top: 13%;
        }
      }
      &:nth-child(5) {
        .box {
          left: -58px;
          transform: translateY(-18%);
          p {
            width: 200px;
          }
          h2 {
            background: #00599aff;
          }
        }
        .qiu-img {
          left: -124px;
          top: 59%;
          transform: translateY(50px);
        }
      }
    }
  }
}
.content-box li:nth-child(5){
  flex: 1;
}
.arrow {
  position: relative;

  .arrow-right {
    position: absolute;
    top: 50%;
    left: -60px;
    transform: translateY(-50%);
    font-size: 50px;
    cursor: pointer;
  }
  .arrow-left {
    position: absolute;
    top: 50%;
    right: -60px;
    transform: translateY(-50%);
    font-size: 50px;
    cursor: pointer;
  }
}
</style>
