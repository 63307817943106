/**
 * Created by shiyuanfu on 2023/6/19.
 */

import axios from 'axios'
import router from '../router'
import siteConfig from "./config";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { Message } from 'element-ui'

// NProgress Configuration
NProgress.configure({
    showSpinner: false
});

var instance = axios.create({
  timeout: siteConfig.tokenTime,
  baseURL: siteConfig.baseUrl ,
  validateStatus(status) {
    switch (status) {
      case 400:
        Message.error('请求出错')
        break
      case 401:
        Message.warning({
          message: '授权失败，请重新登录'
        })
        // store.commit('LOGIN_OUT')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
        return
      case 403:
        Message.warning({
          message: '拒绝访问'
        })
        break
      case 404:
        Message.warning({
          message: '请求错误,未找到该资源'
        })
        break
      case 500:
        Message.warning({
          message: '服务端错误'
        })
        break
    }
    return status >= 200 && status < 300
  }
})

// 添加请求拦截器
instance.interceptors.request.use(
    function(config) {

      NProgress.start() // start progress bar
      config.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
      return config
    },
    function(error) {
      return Promise.reject(error)
    }
)

// 响应拦截器即异常处理
instance.interceptors.response.use(
    response => {
        NProgress.done();
      return response.data
    },
    err => {NProgress.done();
      if (err && err.response) {
        console.log('err.response')
      } else {
        err.message = '连接服务器失败'
      }
      return Promise.reject(err.response)
    }
)

// 结果回调
function promiseThen(res,resolveCall,rejectCall) {
    if (res.code === 0) {
        resolveCall(res.data);
    }else if(res.code==402){
        // 判断是否已存在Message
        if (document.getElementsByClassName('el-message').length === 0) {
            Message.error({
                message: res.msg
            })
        }
        setTimeout(()=>{
            router.push('/index');
        },200)
    } else {
        // 判断是否已存在Message
        if (document.getElementsByClassName('el-message').length === 0) {
            Message.error({
                message: res.msg
            })
        }
        rejectCall(res)
    }
}

// get 请求
function getAxios(url,data, options) {
  data = data || {}
  return new Promise((resolve, reject) => {
    instance
        .get(url,{
          params: data
        }, options)
        .then(response => {
            promiseThen(response,resolve,reject)
        })
        .catch(err => {
            catchCallback(err,'get',url)
        })
  })
}
// post 请求
function postAxios(url, data, options) {
  data = data || {}
  return new Promise((resolve, reject) => {
    instance
        .post(url, data, options)
        .then(response => {
            promiseThen(response,resolve,reject)
        })
        .catch(err => {
            catchCallback(err,'post',url)
        })
  })
}


// err 出错obj type 请求方式 URL 请求url
function catchCallback(err,type,url) {
    if(err && err.code==402){
        setTimeout(()=>{
            router.push('/index');
        },200)
    }else {
        if(process.env.VUE_APP_ENV=='production'){
            console.log(type+'Axios //='+url+' =// catch:服务端出错')
        }else{
            console.log(type+'Axios //='+url+' =// catch:'+JSON.stringify(err))
        }
    }
}


export default {
    get: function (url, data,options) {
        return getAxios(url, data, options)
    },
    post: function (url, data,options) {
        return postAxios(url, data, options)
    },
}
