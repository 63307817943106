<template>
  <div>
    <!-- 轮播 -->
    <!-- <shBanner :pagesName="'aboutMyCode'" :bannerHeight="'476px'"></shBanner> -->
    <!-- 路径 -->
    <!-- <pathRoute :routeTest="routeTest"   :activeIndex="'3'" /> -->
    <div class="corporate-culture">
      <!-- 内容 -->
      <div class="head">
        <h2>企业视频</h2>
        <p>Enterprise Video</p>
      </div>
      <div class="video-box" v-html="viewInfo.content">
        <!-- 等待视频压缩 -->
        <!-- <video
              muted="muted"
              class="video"
              src="@/assets/imgs/aboutUs/xcp.mp4"
              type="video/mp4"
              controls
              :autoplay="false"
            ></video> -->

        <!-- <video width="320" height="240" controls>
            <source :src="xcp" type="video/mp4" />
          </video> -->
        <!-- <video src="../../assets/imgs/aboutUs/xcp.MP4"></video> -->
      </div>
    </div>
  </div>
</template>
<script>
// import shBanner from "@/components/sh_banner.vue";
// import pathRoute from "@/components/pathRoute.vue";
import { getShContent } from '@/api/HomeView/index'
import videoImg from '@/assets/imgs/sh_videofm.png'
export default {
  components: {
    // pathRoute,
    // shBanner,
  },
  data() {
    return {
      routeTest: [
        { title: "企业简介", path: '/enterpriseIntroduce' },
        { title: "企业文化", path: '/corporateCulture' },
        { title: "发展历程", path: "/developmentHistory" },
        { title: "企业视频", path: '/enterpriseVideo' },
      ],
      viewInfo: {}
    };
  },
  methods: {
    // 获取系统 内容信息
    getViewData() {
      getShContent(4).then((res) => {
        this.viewInfo = res
      })
    },
     // 设置视频封面
    setVideo() {
      let videop = document.getElementsByTagName('video')[0]
      videop.poster =videoImg
    }
  },

  // 初始化载入
  mounted() {
    let _this = this
    // 初始化
    _this.getViewData()
    // 设置视频封面
    setTimeout(() => {
    _this.setVideo()
    }, 2000);
  }
};
</script>
<style lang="scss" scoped>
.corporate-culture {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 88px;
  margin-top: 23px;

  .head {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;

    h2 {
      font-size: 32px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      text-align: center;
      color: #333333;
    }

    p {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      text-align: center;
      color: #999999;
    }
  }

  .video-box {
    width: 775px;
    height: 436px;
    margin: 0 auto;
  }
}</style>
