<template>
  <!-- 晶戈展 产品详情  -->
  <div class="content-box">
    <!-- 轮播 -->
    <shBanner :pagesName="'goodsCode'" :bannerHeight="'476px'"></shBanner>
    <!-- 路径 -->
    <!-- <pathRoute :routeTest="routeTest" /> -->
    <!-- 中间内容部分 -->
    <div class="product">
      <div class="product-switch-box">
        <div class="product-switch">
          <div class="product-switch-top">
            <i class="el-icon-arrow-left" @click="replaceBan(1)"></i>
            <div class="product-imgs-hov">
              <img class="product-imgs" :src="filePathUrl + JSON.parse(detailsInfo?.imgs)[replaceBanIdex]" />
            </div>
            <i class="el-icon-arrow-right" @click="replaceBan(2)"></i>
          </div>
          <div class="carousel-list">
            <img class="carousel-imgs" v-for="(item, index) in JSON.parse(detailsInfo?.imgs)" :key="index"
              @click="carouselBan(index)" :src="filePathUrl + item"
              :style="{ border: replaceBanIdex == index ? '1px solid #0261B8' : '' }" />
          </div>
        </div>
        <!-- 内容 -->
        <div class="product-content">
          <span class="product-title"> {{ detailsInfo.name }}</span>
          <div class="product-picture">
            {{ detailsInfo.intro }}
          </div>
          <div class="product-operate">
            <!-- <div class="operate-item" @click="upClick()">上一个产品
              </div>
              <div class="operate-item" @click="downClick()">下一个产品
              </div> -->
            <el-tooltip :content="(nextObj.pre?.name) ? nextObj.pre?.name : '暂无数据'" placement="top" effect="light">
              <div class="operate-item" @click="upClick(nextObj.pre?.id)">上一个：{{ (nextObj.pre?.name) ?
                nextObj.pre?.name :
                '暂无数据' }}
              </div>
            </el-tooltip>
            <el-tooltip :content="(nextObj.next?.name) ? nextObj.next?.name : '暂无数据'" placement="top" effect="light">
              <div class="operate-item" @click="downClick(nextObj.next?.id)">下一个：{{ (nextObj.next?.name) ?
                nextObj.next?.name :
                '暂无数据' }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品详情 -->
    <div class="product-bgcolor">
      <div class="product">
        <div class="product-details">
          <div class="product-details-line">
            <span class="product-details-title">产品详情</span>
          </div>
          <div class="details-details-list">
            <div class="details-details-item" v-html="detailsInfo.details"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 更多产品 -->
    <div class="product" v-if="detailsList.length > 0">
      <span class="more-title">更多产品</span>
      <div class="more-list">
        <div class="more-item" v-for="(item, index) in detailsList" :key="index" @click="toDetils(item, index)">
          <div class="more-item-img-hov">
            <img class="more-item-img hvr-grow" :src="filePathUrl + item.img" />
          </div>
          <span class="more-item-name">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shBanner from "@/components/sh_banner.vue";
// import pathRoute from "@/components/pathRoute.vue";
import {
  getProduct,
  getProductList,
  getGoodsNext
} from "@/api/HomeView/index";

export default {
  name: "HomeView",
  components: {
    shBanner,
    // pathRoute,
  },
  data() {
    return {
      filePathUrl: process.env.VUE_APP_BASE_API,
      detailsInfo: {},
      listTotal: 0,
      nextObj: {},
      routeTest: [
        { title: "网站首页", path: "/" },
        { title: "产品中心", path: "/enterpriseIntroduce/productMain" },
        { title: "营养大健康产品", path: "/enterpriseIntroduce/productMain" },
      ],
      goodsList: [],
      detailsList: [],
      replaceBanIdex: 0,
      queryData: {
        id: undefined,
        needCategory: undefined,
        recommendStatus: undefined,
        sortType: undefined
      }
    };
  },
  methods: {
    // 获取详情信息
    getViewDetailsData() {
      getProduct(this.queryData.id).then((res) => {
        this.detailsInfo = res ? res : {};
      });
    },
    // 获取 详情-查询上一个-下一个新闻信息
    getViewData() {
      getGoodsNext(this.queryData).then((res) => {
        this.nextObj = res
        this.getViewDetailsData()
      })
    },

    // 获取详情列表信息
    getViewDataList() {
      getProductList().then((res) => {
        this.detailsList = res;
      });
    },
    // 上一个
    upClick(id) {
      let _this = this
      if (id) {
        this.queryData.id = id
        _this.getViewData()
      } else {
        this.$message.warning('无更多产品')
      }
    },
    // 下一个
    downClick(id) {
      let _this = this
      if (id) {
        this.queryData.id = id
        _this.getViewData()
      } else {
        this.$message.warning('无更多产品')
      }
    },
    // 轮播指示灯点击
    carouselBan(index) {
      this.replaceBanIdex = index;
    },
    // 轮播左右箭头点击
    replaceBan(type) {
      if (type == 1) {
        --this.replaceBanIdex;
        if (this.replaceBanIdex <= 0) {
          this.replaceBanIdex = 0;
        }
      } else if (type == 2) {
        if (
          this.replaceBanIdex <
          JSON.parse(this.detailsInfo?.imgs).length - 1
        ) {
          ++this.replaceBanIdex;
        } else {
          this.replaceBanIdex = JSON.parse(this.detailsInfo?.imgs).length - 1;
        }
      }
    },
    // 跳转详情
    toDetils(item) {
      this.queryData.id = item.id
      this.queryData.recommendStatus = 0;
      this.getViewData()
    },
  },
  // 初始化载入
  mounted() {
    let _this = this;
    // 初始化
    _this.queryData.id = this.$route.query?.id;
    _this.queryData.sortType = this.$route.query?.sortTyp;
    _this.queryData.needCategory = this.$route.query?.needCategory;
    _this.getViewData();
    _this.getViewDataList();
  },
};
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
}

.content-box {
  display: flex;
  flex-direction: column;
  background-color: #fff;

}

/* 中间内容部分 --start */
.product {
  display: flex;
  flex-direction: column;
  width: 1200px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 35px;
}

.product-switch-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-switch {
  display: flex;
  flex-direction: column;
  flex: 3;
  margin-right: 45px;
  justify-content: center;
}

.product-switch-top {
  display: flex;
  align-items: center;
}

.product-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  flex: 2;
}

.product-title {
  font-size: 28px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  text-align: left;
}

.product-picture {
  display: flex;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #666666;
  margin-top: 20px;
  text-align: left;
}

.product-operate {
  display: flex;
  text-align: right;
  padding-top: 100px;
}

.el-icon-arrow-left,
.el-icon-arrow-right {
  font-size: 30px;
  cursor: pointer;
  color: #999;
}

.product-imgs-hov {
  width: 560px;
  height: 350px;
  margin: 0 10px;
  overflow: hidden;
  text-align: center;
}

.product-imgs {
  max-width: 100%;
  max-height: 100%;
}

.carousel-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0 80px 0;
  box-sizing: border-box;
}

.carousel-imgs {
  width: 54px;
  height: 33px;
  margin-right: 18px;
  cursor: pointer;
}

.operate-item {
  width: 270px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-right: 70px;
  text-align: left;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333;
}

.operate-item:hover {
  color: #0261B8;
  border: 1px solid #0261B8;

}

.product-see-icon {
  width: 560px;
  height: 350px;
}

.product-bgcolor {
  width: 100%;
  background: #f8f8f8;
  padding: 40px 0;
  box-sizing: border-box;
}

.product-details {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.product-details-line {
  display: block;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #f4f4f4;
  text-align: left;
}

.product-details-title {
  padding-bottom: 10px;
  border-bottom: 2px solid #0261b8;
  text-align: left;
  font-size: 20px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #0261b8;
}

.details-details-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.details-details-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 25px;
  width: 100%;
}

.details-details-key {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-bottom: 8px;
}

.details-details-value {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.more-title {
  font-size: 20px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #0261b8;
  text-align: left;
}

.more-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 100px;
}

.more-item {
  padding-right: 30px;
  box-sizing: border-box;
  margin-bottom: 30px;
  flex-direction: column;
  width: 25%;

}

.more-item:nth-child(4n) {
  padding-right: 0px;
}

.more-item-img-hov {
  width: 100%;
  height: 180px;
  overflow: hidden;
  text-align: center;
}

.more-item-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.more-item-name {
  display: block;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin-top: 20px;

}

/* 中间内容部分 --end */
</style>
