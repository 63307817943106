<template>
  <div>
    <!-- 轮播 -->
    <!-- <shBanner :pagesName="'aboutMyCode'" :bannerHeight="'476px'" ></shBanner> -->
    <!-- 路径 -->
    <!-- <pathRoute :routeTest="routeTest" :activeIndex="'0'" /> -->
    <!-- <div class="corporate-culture" v-html="viewInfo.content"> -->
    <div class="corporate-culture" >
      <div class="content-box">
        <div class="box">
          <div class="cont-bg"></div>
          <div class="content">
            <p v-for="(item, index) in content" :key="index">{{ item }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import pathRoute from "@/components/pathRoute.vue";
// import shBanner from "@/components/sh_banner.vue";
import { getShContent } from '@/api/HomeView/index'

export default {
  components: {
    // pathRoute,
    // shBanner,
  },
  data() {
    return {
      routeTest: [
        { title: "企业简介", path: '/enterpriseIntroduce' },
        { title: "企业文化", path: '/corporateCulture' },
        { title: "发展历程", path: "/developmentHistory" },
        { title: "企业视频", path: '/enterpriseVideo' },
      ],
      viewInfo: {},
      content: [
        `贵阳晶戈展珠宝有限公司成立于2023年04月21日，注册地位于贵州省贵阳市云岩区市西河街道瑞金中路77号百灵时尚天地第19楼08号。为立足国情推动绿色产业、促民生谋发展，打造了以黄金、酱酒为主的全品类电商平台“晶戈展电子商城”，主要经营范围包含珠宝首饰销售以及生活用品、金银制品加工销售、预制品食品类销售。
以“黄金”“酱酒”为主营产品，以产业转型升级需求为导向，着力配合各地政府，将各地特色产品推广至全国，通过建设品牌、打开销路、企业拉动等方面延长产业链，有力助推特色产业发展，全面提升特色产业化经营水平。认真贯彻落实中央一号文件精神，立足民生，促进助推地方发展为企业终生重大责任。`,
      ],

    };
  },
  methods: {
    // 获取系统 内容信息
    getViewData() {
      getShContent(2).then((res) => {
        this.viewInfo = res
      })
    }
  },
  // 初始化载入
  mounted() {
    let _this = this
    // 初始化
    _this.getViewData()

  }
};
</script>
<style lang="scss" scoped>
.corporate-culture {
  width: 1200px;
  margin: 0 auto;
  .content-box {
    margin-bottom: 59px;
    padding: 0;
  }

  .box {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .cont-bg {
      flex: 1;
      background: url("@/assets/imgs/aboutUs/sh-logo.png") no-repeat center;
      background-size: cover;
    }
    .content {
      width: 612px;
      height: 383px;
      padding-top: 11px;
      margin-left: 44px;
      p {
        margin-bottom: 20px;
        margin-top: 0;
        font-size: 16px;
        color: #666666ff;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 24px;
        text-align: left;
      }
    }
  }
}
</style>
