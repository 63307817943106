import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

import aboutUs from "../views/aboutUs/aboutUs.vue";

// import enterpriseIntroduce from "../views/aboutUs/aboutUs.vue";
// import corporateCulture from "../views/aboutUs/aboutUs.vue";
// import developmentHistory from "../views/aboutUs/aboutUs.vue";
// import enterpriseVideo from "../views/aboutUs/aboutUs.vue";

import productMain from "../views/product/index.vue";
import newsMain from "../views/news/index.vue";
import newsDetails from "../views/news/details.vue";

import contactWe from "../views/contactWe/contactWe.vue";

// import recruit from "../views/contactWe/recruit.vue";
// import contact from "../views/contactWe/contact.vue";
import nutritionSystem from "../views/nutritionSystem/index.vue";
import productDetails from "../views/product/details.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView, // 首页
  },
  {
    path: "/aboutUs", // 企业简介
    name: "aboutUs",
    component: aboutUs,
  },
  // {
  //   path: "/enterpriseIntroduce", // 企业简介
  //   name: "enterpriseIntroduce",
  //   component: enterpriseIntroduce,
  // },
  // {
  //   path: "/corporateCulture", // 企业文化
  //   name: "corporateCulture",
  //   component: corporateCulture,
  // },
  // {
  //   path: "/developmentHistory", // 发展历程
  //   name: "developmentHistory",
  //   component: developmentHistory,
  // },
  // {
  //   path: "/enterpriseVideo", // 发展历程
  //   name: "enterpriseVideo",
  //   component: enterpriseVideo,
  // },
  {
    path: "/productMain", // 产品中心
    name: "productMain",
    component: productMain
  }
  ,
  {
    path: "/newsMain", // 新闻动态
    name: "newsMain",
    component: newsMain
  }
  ,
  {
    path: "/newsDetails", // 新闻动态详情页面
    name: "newsDetails",
    component: newsDetails
  }
  ,
  {
    path: "/contactWe", // 人才招聘
    name: "contactWe",
    component: contactWe
  } ,
  
  // {
  //   path: "/recruit", // 人才招聘
  //   name: "recruit",
  //   component: recruit
  // } ,
  // {
  //   path: "/contact", // 联系我们
  //   name: "contact",
  //   component: contact
  // }
  // ,
  {
    path: "/nutritionSystem", // 营养系统
    name: "nutritionSystem",
    component: nutritionSystem
  }
  ,
  {
    path: "/productDetails", // 产品中心详情
    name: "productDetails",
    component: productDetails
  }
];

const router = new VueRouter({
  routes,
  mode: 'history', 
});

export default router;
