<template>
    <!-- 贵阳晶戈展 联系我们  -->
    <div class="content-box">
        <!-- 轮播 -->
        <!-- <shBanner :pagesName="'contactCode'" :bannerHeight="'476px'"></shBanner> -->
        <!-- 路径 -->
        <!-- <pathRoute :routeTest="routeTest" :activeIndex="'1'" /> -->
        <!-- 中间内容部分 -->
        <div class="contact">
            <div class="divider-box">
                <div class="divider-title">
                    <span class="divider-line"></span>
                    <span class="divider-line-title">{{ systemInfo.shortOfficialName }}</span>
                    <span class="divider-line"></span>
                </div>
                <div class="divider-english">
                    {{systemInfo.aboutPrifile}}
                </div>
            </div>
            <div class="contact-box">
                <div class="contact-left">
                    <span class="contact-left-title">联系方式</span>
                    <div class="contact-item">
                        <img class="contact-icon" src="@/assets/imgs/sh_lxwmdh.png" />
                        <div class="contact-value">
                            <span class="contact-value-title">联系电话</span>
                            <a>{{ (systemInfo?.telephone) || '13576233251' }}</a>
                        </div>
                    </div>
                    <div class="contact-item">
                        <img class="contact-icon" src="@/assets/imgs/sh_lxwmyx.png" />
                        <div class="contact-value">
                            <span class="contact-value-title">邮箱</span>
                            <a>{{ (systemInfo?.email) || 'chenfan5560@163.com' }}</a>
                        </div>
                    </div>
                    <div class="contact-item">
                        <img class="contact-icon" src="@/assets/imgs/sh_lxwmgw.png" />
                        <div class="contact-value">
                            <span class="contact-value-title">官网</span>
                            <a> {{ (systemInfo?.website) || 'jxsh.top' }}</a>
                        </div>
                    </div>
                    <div class="contact-item">
                        <img class="contact-icon" src="@/assets/imgs/sh_address_conett.png" />
                        <div class="contact-value">
                            <span class="contact-value-title">地址</span>
                            <a>{{ (systemInfo?.address) || '江西省九江市武宁县工业园万福大道源口四路' }}</a>
                        </div>
                    </div>

                </div>
                <div class="contact-submit">
                    <span class="contact-left-title">留言反馈</span>
                    <div class="connectus-input-box">
                        <el-form :model="formData" :rules="rules" ref="ruleForm" style="width: 100%" :inline="true">
                            <div class="demo-ruleForm">
                                <el-form-item label="" prop="name">
                                    <el-input placeholder="姓名" v-model="formData.name"
                                        style="width: 240px; margin-right: 35px;" />
                                </el-form-item>
                                <el-form-item label="" prop="tel">
                                    <el-input placeholder="电话号码" v-model="formData.tel"
                                        style="width: 240px; margin-right: 35px;" />
                                </el-form-item>
                                <el-form-item label="" prop="email">
                                    <el-input placeholder="联系邮箱" v-model="formData.email" style="width: 240px;" />
                                </el-form-item>
                            </div>
                            <el-form-item label="" prop="message" class="form-item">
                                <el-input type="textarea" :rows="10" placeholder="留言内容" style="width: 100%;"
                                    v-model="formData.message" />
                            </el-form-item>
                        </el-form>
                    </div>
                    <div style="text-align: right;"><button class="connectus-submit" @click="submitMsg">提交留言</button></div>
                </div>
            </div>
            <!-- 地图 -->
            <div style="margin-top: 70px;padding-bottom: 50px;">
                <div id="container" style="width: 100%;height: 450px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
// import shBanner from "@/components/sh_banner.vue";
// import pathRoute from "@/components/pathRoute.vue";
import storage from '@/utils/storage'
import { createMessages } from '@/api/HomeView/index'
import { Message } from 'element-ui'
// 自定义展示内容
function createDOM() {
    var div = document.createElement('div');
    let BMap = window.BMapGL;
    div.style.zIndex = BMap.Overlay.getZIndex(this.point.lat);
    div.style.backgroundColor = '#fff';
    div.style.color = '#333';
    div.style.height = '160px';
    div.style.width = '230px';
    div.style.padding = '2px';
    div.style.lineHeight = '50px';
    div.style.whiteSpace = 'nowrap';
    div.style.MozUserSelect = 'none';
    div.style.fontSize = '12px';
    div.style.borderRadius = '10px';
    div.style.display = 'flex';
    div.style.justifyContent = 'center';
    div.style.alignItems = 'center';
    div.style.flexDirection = 'column';

    var title = document.createElement('div');
    title.style.display = 'block';
    title.style.lineHeight = '16px';
    title.style.fontSize = '16px';
    title.style.fontWeight = '700';
    div.appendChild(title);
    title.appendChild(document.createTextNode(this.properties.title));

    var span = document.createElement('span');
    span.style.wordWrap = 'break-word';
    span.style.lineHeight = '16px';
    span.style.wordWrap = 'break-word';
    span.style.whiteSpace = 'normal';
    span.style.padding = '10px';
    span.style.color = '#666';
    div.appendChild(span);
    span.appendChild(document.createTextNode(this.properties.text));

    // let img = document.createElement('img');
    // img.style.width = '120px';
    // img.src = this.properties.imgSrc;
    // div.appendChild(img);

    var arrow = document.createElement('div');
    arrow.style.position = 'absolute';
    arrow.style.top = '164px';
    arrow.style.left = '106px';
    arrow.style.width = '0';
    arrow.style.height = '0';
    arrow.style.borderColor = 'white transparent transparent transparent';
    arrow.style.borderStyle = 'solid';
    arrow.style.borderWidth = '10px';
    arrow.style.overflow = 'hidden';
    div.appendChild(arrow);

    div.onmouseover = function () {
        this.style.backgroundColor = 'skyblue';
        this.style.color = '#fff';
        span.style.color = '#fff';
        arrow.style.top = '164px';
        arrow.style.borderColor = 'skyblue transparent transparent transparent';
    };

    div.onmouseout = function () {
        this.style.backgroundColor = '#fff';
        this.style.color = '#333';
        span.style.color = '#333';
        arrow.style.borderColor = 'white transparent transparent transparent';
    };
    return div;
}
export default {
    name: 'contactView',
    components: {
        // shBanner,
        // pathRoute
    },
    data() {
        // 验证手机号的正则表达式
        var checkMobile = (rule, value, cb) => {
            const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
            if (regMobile.test(value)) {
                return cb();
            }
            cb(new Error("请输入正确的手机号码"));
        };
        /**
         * 验证电子邮箱格式
         */
         var regEmail = (rule, value, cb) => {
			// 验证手机号的正则表达式
			const regMobile = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
			if (value) {
				if (regMobile.test(value)) {
					return cb();
				}
				cb(new Error("请输入正确的邮箱地址"));
			}else{
				return cb();
			}


		};
        return {
            routeTest: [
                { title: "人才招聘", path: '/recruit' },
                { title: "联系我们", path: '/contact' },
            ],
            systemInfo: storage.get('systemInfo'), // 系统基本信息
            formData: {
                name: '',
                tel: '',
                email: '',
                message: '',
            },
            rules: {
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                tel: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    {
                        validator: checkMobile,
                        message: "请输入正确的手机号码",
                        trigger: "blur"
                    }],
                email: [{ required: false, message: '请输入联系邮箱', trigger: 'blur' },
                {
                    validator: regEmail,
                    message: "请输入正确的邮箱地址",
                    trigger: "blur"
                }],
                message: [{ required: true, message: '请输入留言内容', trigger: 'blur' }],
            },
            //  声明定时器
            timer: null,
        };
    },
    mounted() {
        // * 初始化百度地图key
        this.loadKeyScript();
    },
    methods: {
        // 提交留言
        submitMsg() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    createMessages(this.formData).then(() => {
                        Message.success({
                            message: '提交成功,稍后会有专人与您联系'
                        })
                        this.formData = {
                            name: '',
                            tel: '',
                            email: '',
                            message: '',
                        }
                    })
                }
            })
        },
        // * 初始化百度地图key
        loadKeyScript() {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src =
                " https://api.map.baidu.com/getscript?type=webgl&v=1.0&ak=yMSMxgwQ6nCpwScvmFWK4ibp5rwVD8M9&services=&t=20211018154739";
            // script.src =
            //     " https://api.map.baidu.com/getscript?type=webgl&v=1.0&ak=mjHBsT0EVYRsyQCqPfrfw63LNIHO7wut&services=&t=20211018154739";
            document.body.appendChild(script);
            //  首次执行
            this.check()
        },

        //  检查dom是否执行完成
        check() {
            let _this = this;
            // onMounted 挂载完 dom 获取为空，真实 dom 在对象中
            let dom = document.getElementById('container');
            //  自我调用
            if (!dom) return _this.timer = setTimeout(_this.check(), 0)
            if (!_this.timer) clearTimeout(_this.timer) //  清除定时器
            //  执行dom加载完成后的操作，例如echart的初始化操作
            setTimeout(function () {
                _this.intMap()
            }, 500)
        },

        // * 初始化百度地图
        intMap() {
            var _this = this;
            let markers = []
            _this.$nextTick(function () {
                let BMap = window.BMapGL;
                var map = new BMap.Map("container"); // 创建Map实例
                var point = new BMap.Point(_this.systemInfo.longitude, _this.systemInfo.latitude); // 创建点坐标
                map.centerAndZoom(point, 19);
                map.enableScrollWheelZoom();
                var opts = {
                    position: new BMap.Point(_this.systemInfo.longitude, _this.systemInfo.latitude), // 指定文本标注所在的地理位置
                    offset: new BMap.Size(30, -30), // 设置文本偏移量
                };
                let points = ([
                    { lng: _this.systemInfo.longitude, lat: _this.systemInfo.latitude },
                    // 添加更多的经纬度坐标
                ]);
                // 循环添加多个标记点
                points.forEach((point) => {
                    let bPoint = new BMap.Point(point.lng, point.lat); // 创建点
                    let marker = new BMap.Marker(bPoint); // 做标记
                    map.addOverlay(marker); // 在地图上显示标注点
                    markers.push(marker);
                    // 点击标注监听事件
                    marker.addEventListener("click", function () {
                        // alert("您点击了标注");
                        // console.log(e, 888888888);
                        // 创建文本标注对象
                        var label = new BMap.Label(_this.systemInfo.address, opts);
                        // 自定义文本标注样式
                        label.setStyle({
                            color: "#333",
                            borderRadius: "5px",
                            borderColor: "#ccc",
                            padding: "10px",
                            fontSize: "16px",
                            height: "30px",
                            lineHeight: "30px",
                            fontFamily: "微软雅黑",
                            background: "url(//map.baidu.com/fwmap/upload/r/map/fwmap/static/house/images/label.png) no-repeat",
                            overflow: "hidden"
                        });
                        // map.addOverlay(label);
                        // 创建自定义覆盖物
                        var customOverlay = new BMap.CustomOverlay(createDOM, {
                            point: new BMap.Point(_this.systemInfo.longitude, _this.systemInfo.latitude),
                            opacity: 0.5,
                            offsetY: -60,
                            offsetX: -100,
                            properties: {
                                title: _this.systemInfo.shortOfficialName,
                                text: _this.systemInfo.address,
                                imgSrc: 'https://bj.bcebos.com/v1/mapopen-pub-jsapigl/assets/images/gugong.png'
                            }
                        });
                        map.addOverlay(customOverlay);
                    });
                });

            });
        },

    },

}
</script>
<style scoped>
body {
    margin: 0;
    padding: 0;
}

.content-box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

/* 中间内容部分 --start */
.contact {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 30px;
}

.divider-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-bottom: 35px;
    border-bottom: 2px solid #F4F4F4;
}

.divider-title {
    display: flex;
    align-items: center;
}

.divider-line {
    width: 30px;
    height: 3px;
    background-color: #333333;
}

.divider-line-title {
    font-size: 32px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin: 0 10px;
}

.divider-english {
    width: 400px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-top: 40px;
}

.connectus-input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 45px 0 30px 0;
}

.connectus-submit {
    width: 106px;
    border-radius: 4px 4px 4px 4px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #0261B8;
    margin-top: 30px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    border: none;

}

.contact-box {
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
}

.contact-left {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.contact-left-title {
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: left;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-top: 45px;
}

.contact-icon {
    width: 34px;
    height: 34px;
    margin-right: 10px;
}

.contact-value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.contact-value-title {
    margin-bottom: 5px;
}

.contact-submit {
    display: flex;
    flex-direction: column;
}

/* 中间内容部分 --end */
</style>