<template>
    <!-- 晶戈展 营养系统  -->
    <div class="content-box">
        <!-- 轮播 -->
        <shBanner :pagesName="'nutritionCode'" :bannerHeight="'476px'"></shBanner>
        <!-- 路径 -->
        <!-- <pathRoute :routeTest="routeTest" /> -->
        <!-- 中间内容部分 -->
        <div class="nutritionSystem"  v-html="viewInfo.content">
            <!-- <img class="nutritionSystem-img" src="@/assets/imgs/sh_yyxtlct.png" /> -->
        </div>
    </div>
</template>

<script>
import shBanner from "@/components/sh_banner.vue";
// import pathRoute from "@/components/pathRoute.vue";
import { getShContent } from '@/api/HomeView/index'

export default {
    name: 'HomeView',
    components: {
        shBanner,
        // pathRoute
    },
    data() {
        return {
            routeTest: [
                { title: "网站首页", path: '/' }
            ],
            viewInfo: {}
        };
    },
    methods: {
    // 获取系统 内容信息
    getViewData() {
      getShContent(3).then((res) => {
        this.viewInfo = res
      })
    }
  },
  // 初始化载入
  mounted() {
    let _this = this
    // 初始化
    _this.getViewData()

  }
}
</script>
<style scoped>
body {
    margin: 0;
    padding: 0;
}

.content-box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

/* 中间内容部分 --start */
.nutritionSystem {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 110px;
}
.nutritionSystem img{
    max-width: 100%;
}

.nutritionSystem-img {
    width: 100%;
    height: auto;
}

/* 中间内容部分 --end */
</style>