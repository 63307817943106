<template>
    <div>
        <!-- 联系 440px -->
        <div class="contact-box">
            <a href="/contactWe?index=1">
                <div class="contact-item">
                    <img class="contact-item-img" src="@/assets/imgs/zaixianzx.png">
                    <span class="contact-item-title">在线咨询</span>
                </div>
            </a>

            <el-popover placement="right" width="300" trigger="hover">
                <div class="phone-box">
                    <img class="phone-box-img" src="@/assets/imgs/ptrxphone.png">
                    <div class="phone-num"><span class="phone-box-title">平台热线电话</span>{{ (systemInfo?.telephone) ||
                        '17620428222' }}</div>
                </div>
                <div slot="reference" class="contact-item">
                    <img class="contact-item-img" src="@/assets/imgs/rxdh.png">
                    <span class="contact-item-title">热线电话</span>
                </div>
            </el-popover>

            <el-popover placement="right" width="160" trigger="hover">
                <img class="code-img" v-if="systemInfo?.wechatOfficialUrl" :src="filePathUrl + systemInfo?.wechatOfficialUrl">
                <img class="code-img" v-else src="@/assets/imgs/sh_code.png">
                <div slot="reference" class="contact-item">
                    <img class="contact-item-img" src="@/assets/imgs/zhixuncode.png">
                    <span class="contact-item-title">扫码咨询</span>
                </div>
            </el-popover>
        </div>
    </div>
</template>
  
<script>
import storage from '@/utils/storage'

export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data() {
        return {
            filePathUrl: process.env.VUE_APP_BASE_API,
            systemInfo: storage.get('systemInfo') // 系统基本信息
        };
    }
}
</script>
  
<style scoped>
/* 联系消息框  --start */
.contact-box {
    position: fixed;
    top: 540px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    z-index: 888;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

.contact-item {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid #F4F4F4;
    cursor: pointer;
}

.contact-item-img {
    width: 31px;
    height: 31px;
}

.contact-item-title {
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    margin-top: 10px;
}

.phone-box {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.phone-box-img {
    width: 50px;
    height: 50px;
    margin-top: 10px;
}

.phone-num {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.phone-box-title {
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin: 0 15px;
}

.code-img {
    width: 170px;
    height: 160px;
    cursor: pointer;
}

/* 联系消息框  --end */
</style>
  