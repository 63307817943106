<template>
    <!-- 贵阳晶戈展 人才招聘  -->
    <div class="content-box">
        <!-- 轮播 -->
        <!-- <shBanner :pagesName="'contactCode'" :bannerHeight="'476px'"></shBanner> -->
        <!-- 路径 -->
        <!-- <pathRoute :routeTest="routeTest" :activeIndex="'0'"/> -->
        <!-- 中间内容部分 -->
        <div class="recruit">
            <div class="divider-box">
                <div class="divider-title">
                    <span class="divider-line"></span>
                    <span class="divider-line-title">{{ systemInfo.shortOfficialName }}</span>
                    <span class="divider-line"></span>
                </div>
                <div class="divider-english">
                    {{systemInfo.bossProfile}}
                </div>
            </div>
            <div class="ask-list">
                <div class="ask-item" v-for="(item, index) in recruitList" :key="index">
                    <div class="ask-station">
                        <span class="ask-station-num">{{ item.jobTitle }}：{{ item.number ? item.number : '0' }}人</span>
                        <span class="ask-station-pay">薪资待遇：{{ item.salary }}/{{ item.education }}/{{ item.sex }}</span>
                    </div>
                    <button class="ask-contribute" @click="dialogVisible = true">投递简历→</button>
                    <span class="ask-title">任职要求</span>
                    <div class="ask-ul" v-html="item.jobDescription"></div>
                </div>
            </div>
            <div class="recruit-pagination">
                <pagination v-show="listTotal > 0" :total="listTotal" :page.sync="queryParams.pageNo"
                    :limit.sync="queryParams.pageSize" :pageSizes="pageSizes"  @pagination="getViewData"  />
            </div>
        </div>
        <!-- 投递简历 弹窗-->
        <el-dialog title="" :visible.sync="dialogVisible" width="890px">
            <div class="vita-dialog">
                <span class="vita-title">欢迎投递简历</span>
                <span class="vita-tips">我们欢迎对互联网领域有兴趣、有梦想、有创造力的你与我们一起成长</span>
                <div class="vita-msg">
                    <div class="vita-information">
                        <div class="vita-information-tips">请把简历投到我们的邮箱（岗位+姓名）</div>
                        <div class="vita-information-emile">贵阳晶戈展邮箱：<a>{{ systemInfo.email }}</a></div>
                        <button class="vita-contribute" @click="dialogVisible = false">关闭</button>
                    </div>
                    <img class="vita-information-img" src="@/assets/imgs/sh_tjlimg.png" />
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
// import shBanner from "@/components/sh_banner.vue";
// import pathRoute from "@/components/pathRoute.vue";
import Pagination from "@/components/Pagination.vue";

import storage from '@/utils/storage'
import { getRecruitmentPage } from '@/api/HomeView/index'

export default {
    name: 'recruitView',
    components: {
        // shBanner,
        // pathRoute,
        Pagination
    },
    data() {
        return {
            dialogVisible: false,
            routeTest: [
                { title: "人才招聘", path: '/recruit' },
                { title: "联系我们", path: '/contact' },
            ],
            recruitList: [],
            systemInfo: storage.get('systemInfo'),// 系统基本信息
            queryParams: {
                pageNo: 1,
                pageSize: 10,
            },
            listTotal: 0,
            pageSizes:[10, 20, 50, 100]
        };
    },
    methods: {
        // 获取首页招聘中心信息
        getViewData() {
            let data = {
                pageNo: this.queryParams.pageNo,
                pageSize: this.queryParams.pageSize
            }
            getRecruitmentPage(data).then((res) => {
                this.recruitList = res.list
                this.listTotal = res.total
            })
        }
    },
    // 初始化载入
    mounted() {
        let _this = this
        // 初始化
        _this.getViewData()

    }
}
</script>
<style scoped>
body {
    margin: 0;
    padding: 0;
}

.content-box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

/* 中间内容部分 --start */
.recruit {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 30px;
}

.divider-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-bottom: 35px;
    border-bottom: 2px solid #F4F4F4;
}

.divider-title {
    display: flex;
    align-items: center;
}

.divider-line {
    width: 30px;
    height: 3px;
    background-color: #333333;
}

.divider-line-title {
    font-size: 32px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin: 0 10px;
}

.divider-english {
    width: 660px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-top: 40px;
}


.recruit-pagination {
    margin: 50px 0;
}

.ask-list {
    display: flex;
    flex-direction: column;
}

.ask-item {
    display: flex;
    flex-direction: column;
    padding: 55px 0 35px 0;
    box-sizing: border-box;
    border-bottom: 2px solid #F4F4F4;
}

.ask-station {
    display: flex;
    align-items: center;
}

.ask-station-num {
    font-size: 28px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-right: 60px;
}

.ask-station-pay {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.ask-contribute {
    width: 106px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #F7A444;
    border-radius: 4px 4px 4px 4px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: auto;
    border: none;
    cursor: pointer;
}

.ask-title {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: left;
}

.ask-ul {
    margin-top: 20px;
    padding-left: 40px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 40px;
    color: #333333;
}


.ask-li {
    text-align: left;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: 15px;
}

/* 投递简历 弹窗-
 */
.vita-dialog {
    display: flex;
    flex-direction: column;
    padding: 0 40px;
}

.vita-title {
    font-size: 32px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    text-align: left;
}

.vita-tips {
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    text-align: left;
    margin-top: 15px;
}

.vita-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vita-information {
    display: flex;
    flex-direction: column;
}

.vita-information-tips {
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    text-align: left;
    margin-top: 35px;
}

.vita-information-emile {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-top: 15px;
    text-align: left;
}

.vita-contribute {
    width: 106px;
    height: 40px;
    line-height: 40px;
    background: #0261B8;
    border-radius: 4px 4px 4px 4px;
    border: none;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 80px;
    cursor: pointer;

}

.vita-information-img {
    width: 250px;
    height: 252px;
}

/* 中间内容部分 --end */
</style>