<template>
	<div>
		<!-- 轮播 -->
		<div class="banner-box">
			<el-carousel :interval="3000" arrow="always" :height="bannerHeight">
				<template v-for="item in banList">
					<el-carousel-item v-for="(bItem, bIindex) in JSON.parse(item.imagePath) " :key="bIindex">
						<a :href="item.linkUrl?item.linkUrl:'javascript:;'">
							<div class="ban-imgs animate__animated animate__fadeInLeft"
								:style="{ backgroundImage: 'url(' + filePathUrl + bItem + ')' }"></div>
							<!-- <div class="count-box" v-if="pagesName == 'mianCode'">
								<div class="count-item animate__animated animate__flipInX">
									<span class="count-num animate__animated">
										{{ Number(systemInfo?.totalNvestment )|| '0' }}
										<span class="count-unit">亿元</span>
									</span>
									<span class="unit-title">总投资</span>
								</div>
								<div class="count-item animate__animated animate__flipInX">
									<span class="count-num">
										<countTo :startVal='1' :endVal='Number(systemInfo?.devCount) || 0'
											:duration='5000'>
										</countTo><span class="count-unit">+</span>
									</span>
									<span class="unit-title">研发人员</span>
								</div>
								<div class="count-item animate__animated animate__flipInX">
									<span class="count-num">
										<countTo :startVal='Number(systemInfo?.constructionArea - 100) || 0'
											:endVal='Number(systemInfo?.constructionArea || 0)' :duration='6000'>
										</countTo><span class="count-unit">平方米</span>
									</span>
									<span class="unit-title">建设面积</span>
								</div>
								<div class="count-item animate__animated animate__flipInX">
									<span class="count-num">
										<countTo 
										:startVal='1' 	:endVal='Number(systemInfo?.productionLine || 0)' :duration='5000'>
										</countTo>
										<span class="count-unit">条生产</span>
									</span>
									<span class="unit-title">液态、固态、特医</span>
								</div>
							</div> -->
						</a>

					</el-carousel-item>
				</template>

			</el-carousel>
		</div>
	</div>
</template>
<script>
import storage from '@/utils/storage'
import { getSlideshow } from '@/api/HomeView/index'
// import countTo from 'vue-count-to';

export default {
	// components: {
	// 	countTo
	// },
	props: {
		pagesName: String,
		bannerHeight: String
	},
	data() {
		return {
			systemInfo: storage.get('systemInfo'), // 系统基本信息
			banList: [], // 轮播
			filePathUrl: process.env.VUE_APP_BASE_API, // 图片基准路径
		};
	},
	methods: {
		// 查询公司获得轮播图信息
		getBanData() {
			let _this = this
			getSlideshow(_this.pagesName).then((res) => {
				this.banList = res
			})
		},
	},
	// 初始化载入
	mounted() {
		let _this = this
		// 初始化
		_this.getBanData()
	}
}
</script>
<style scoped>
/* 轮播图 --start */
.banner-box {
	position: relative;
}

.ban-imgs {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

/* 轮播图 -- end */

/* 统计 */
.count-box {
	width: 100%;
	height: 143px;
	position: absolute;
	left: 0px;
	bottom: 0;
	display: flex;
	align-items: center;
	background: #FFFFFF;
	z-index: 99;
	background: rgba(0, 0, 0, 0.4);
}

.count-item {
	display: flex;
	flex-direction: column;
	margin-left: 170px;
	font-size: 24px;
	font-family: Lantinghei TC-Demibold, Lantinghei TC;
	font-weight: 500;
	color: #FFFFFF;
	text-align: left;
}

.count-num {
	font-size: 60px;
}

.count-unit {
	font-size: 18px;

}
</style>
  