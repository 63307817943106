
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'hover.css'
import 'animate.css';
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.config.errorHandler = function(err) {
  console.log("global", err);
};
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
