<template>
    <div :class="{ 'hidden': hidden }" class="pagination-container">
        <el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize" :layout="layout"
            :page-sizes="pageSizes" :total="total" v-bind="$attrs" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" prev-text="上一页" next-text="下一页" />
    </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
    name: 'PaginatioName',
    props: {
        total: {
            required: true,
            type: Number
        },
        page: {
            type: Number,
            default: 1
        },
        limit: {
            type: Number,
            default: 20
        },
        pageSizes: {
            type: Array,
            default() {
                return [10, 20, 30, 50]
            }
        },
        layout: {
            type: String,
            default: 'prev, pager, next'
        },
        background: {
            type: Boolean,
            default: true
        },
        autoScroll: {
            type: Boolean,
            default: true
        },
        hidden: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        currentPage: {
            get() {
                return this.page
            },
            set(val) {
                this.$emit('update:page', val)
            }
        },
        pageSize: {
            get() {
                return this.limit
            },
            set(val) {
                this.$emit('update:limit', val)
            }
        }
    },
    methods: {
        handleSizeChange(val) {
            this.$emit('pagination', { page: this.currentPage, limit: val })
            if (this.autoScroll) {
                scrollTo(0, 800)
            }
        },
        handleCurrentChange(val) {
            this.$emit('pagination', { page: val, limit: this.pageSize })
            if (this.autoScroll) {
                scrollTo(0, 800)
            }
        }
    }
}
</script>
<style>
.btn-prev {
    margin: 0;
    padding: 15px 23px !important;
    border: 1px solid #eee !important;

}
.el-pagination .btn-next {
    padding-left: 12px;
    padding: 15px 23px !important;
    border: 1px solid #eee !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0261B8 !important;
}
.pagination-container {
    text-align: center;
}

.el-pagination {
    white-space: nowrap;
    color: #303133;
    font-weight: 700;
    padding: 15px 23px !important;

}

.el-pagination button {
    margin: 0 !important;
    height: auto !important;
}

.el-pager {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    list-style: none;
    font-size: 0;
    border: 1px solid #eee;

}

.el-pager li {
    background: #FFF;
    font-size: 13px;
    /* min-width: 35.5px; */
    height: auto !important;
    line-height: auto;
    box-sizing: border-box;
    text-align: center;
    padding: 15px 23px !important;
    margin: 0 !important;
}
</style>
<style scoped>
.pagination-container {
    background: #fff;
    padding: 32px 16px;
}

.pagination-container.hidden {
    display: none;
}</style>
