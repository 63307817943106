<template>
    <div>
        <!-- 轮播 -->
        <shBanner :pagesName="'aboutMyCode'" :bannerHeight="'476px'"></shBanner>
        <!-- 路径 -->
        <pathRoute :routeTest="routeTest"  @activeClick="activeClick" />
        <!-- 内容 -->
        <enterpriseIntroduce v-if="activeIndex == 0"></enterpriseIntroduce>
        <corporateCulture v-if="activeIndex == 1"></corporateCulture>
        <developmentHistory v-if="activeIndex == 2"></developmentHistory>
        <enterpriseVideo v-if="activeIndex == 3"></enterpriseVideo>
    </div>
</template>
<script>
import pathRoute from "@/components/pathRoute.vue";
import shBanner from "@/components/sh_banner.vue";
import enterpriseIntroduce from "./enterpriseIntroduce.vue";
import corporateCulture from "./corporateCulture.vue";
import developmentHistory from "./developmentHistory.vue";
import enterpriseVideo from "./enterpriseVideo.vue";
export default {
    components: {
        pathRoute,
        shBanner,
        enterpriseIntroduce,
        corporateCulture,
        developmentHistory,
        enterpriseVideo
    },
    data() {
        return {
            activeIndex: 0,
            routeTest: [
                { title: "企业简介", path: '/enterpriseIntroduce' },
                { title: "企业文化", path: '/corporateCulture' },
                // { title: "发展历程", path: "/developmentHistory" },
                // { title: "企业视频", path: '/enterpriseVideo' },
            ],
        };
    },
    methods: {
        activeClick(val) {
            this.activeIndex = val.index
        }
    },
    watch: {
        $route: {
            handler(newval) {
                this.activeIndex =Number(newval?.query?.index) 
            },
            // 深度观察监听
            deep: true
        }
    },

    // 初始化载入
    mounted() {
        // 初始化
        this.activeIndex = Number(this.$route?.query?.index) 
    },
};
</script>
<style lang="scss" scoped></style>
  