<template>
  <div>
    <!-- 轮播 -->
    <!-- <shBanner :pagesName="'aboutMyCode'" :bannerHeight="'476px'" ></shBanner> -->
    <!-- 路径 -->
    <!-- <pathRoute :routeTest="routeTest"  :activeIndex="'1'"/> -->

    <div class="corporate-culture">
      <!-- <div class="content-box" v-html="viewInfo.content"> -->
      <div class="content-box">
        <!-- 内容 -->
        <div class="txt">
          <div class="cont1">
            <p>
              打造出新零售电商平台
              全方位立体化的构成全产业链优质电商生态圈
              严格把控重要环节
            </p>
            <p>
              凝聚发展合力，助推各地方特色产品产业发展
            </p>
            <p>以“黄金”“酱酒”为主营产品</p>
          <p>
            以产业转型升级需求为导向
          </p>
          <p>
            着力配合各地政府,将各地特色产品推广至全国
          </p>
          </div>
          <div class="logo"></div>
        </div>
        <div class="cont1 cont2">
          <p>
            通过建设品牌、打开销路、企业拉动等方面延长产业链，有力助推特色产业发展，全面提升特色产业化经营水平。认真贯彻落实中央一号文件精神，立足民生，促进助推地方发展为企业终生重大责任
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import shBanner from "@/components/sh_banner.vue";
// import pathRoute from "@/components/pathRoute.vue";
import { getShContent } from '@/api/HomeView/index'

export default {
  components: {
    // pathRoute,
    // shBanner,
  },
  data() {
    return {
      routeTest: [
        { title: "企业简介", path: '/enterpriseIntroduce' },
        { title: "企业文化", path: '/corporateCulture' },
        { title: "发展历程", path: "/developmentHistory" },
        { title: "企业视频", path: '/enterpriseVideo' },
      ],
      viewInfo: {}
    };
  },
  methods: {
    // 获取系统 内容信息
    getViewData() {
      getShContent(1).then((res) => {
        this.viewInfo = res
      })
    }
  },
  // 初始化载入
  mounted() {
    let _this = this
    // 初始化
    _this.getViewData()

  }
};
</script>
<style lang="scss" scoped>
.corporate-culture {
  width: 1200px;
  margin: 0 auto;

  .content-box {
    margin-bottom: 145px;
    padding: 0;
  }

  .txt {
    margin-top: 83px;
    display: flex;
    justify-content: space-between;
  }

  .cont1 {
    width: 393px;

    p {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #666666;
      text-align: left;
      line-height: 30px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .logo {
    width: 519px;
    margin-top: 25px;
    margin-bottom: 15px;
    background: url("@/assets/imgs/aboutUs/sh-logo.png") no-repeat left center;
    background-size: contain;
  }

  .cont2 {
    width: 100%;

    p {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }
}
</style>
