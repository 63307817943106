<template>
    <!-- 晶戈展 产品中心  -->
    <div class="content-box">
        <!-- 轮播 -->
        <shBanner :pagesName="'goodsCode'" :bannerHeight="'476px'"></shBanner>
        <!-- 路径 -->
        <!-- <pathRoute :routeTest="routeTest" /> -->
        <div class="allPathRoute">
            <div class="path-route">
                <span class="path-route-rigth" v-for="(item, index) in goodsTypeList" :key="index"
                    @click="topTypeClick(index, item.id)" :style="{ color: activeIndex == index ? '#0261B8' : '' }">
                    {{ item.name }}
                </span>
            </div>
        </div>
        <div style="width: 1200px;margin: 0 auto;">
            <div class="type-box">
                <div class="type-item" :class="queryData.sortType == 1 ? 'active-type' : ''" @click="typeClick(1)">
                    推荐
                    <i class="el-icon-caret-top"></i>
                </div>
                <div class="type-item" :class="queryData.sortType == 2 ? 'active-type' : ''" @click="typeClick(2)">
                    热门
                    <i class="el-icon-caret-top"></i>
                </div>
                <div class="type-item" :class="queryData.sortType == 3 ? 'active-type' : ''" @click="typeClick(3)">
                    最新
                    <i class="el-icon-caret-bottom"></i>
                </div>
            </div>
        </div>
        <!-- 中间内容部分 -->
        <div style="background-color: #F8F8F8;">
            <div class="product">
                <div class="machine-big">
                    <template v-for="(item, index) in goodsList">
                        <div class="machine-big-item animate__animated animate__fadeInLeft" @click="toDetils(item)"
                            :key="index" v-if="index == 0 || index == 1">
                            <div class="machine-big-imgs">
                                <img class="machine-big-img hvr-grow" :src="filePathUrl + item.img" />
                            </div>
                            <div class="machine-big-name">
                                {{ item.name }}
                            </div>
                        </div>
                    </template>
                </div>
                <div class="machine">
                    <template v-for="(item, index) in goodsList">
                        <div class="machine-item animate__animated animate__fadeInLeft" @click="toDetils(item)" :key="index"
                            v-if="index != 0 && index != 1">
                            <div>
                                <div class="machine-imgs">
                                    <img class="machine-img hvr-grow" :src="filePathUrl + item.img" />
                                </div>
                                <div class="machine-name">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-if="goodsList.length <= 0" style="color: #333;font-size: 20px;text-align: center;padding: 50px;">暂无数据
            </div>

        </div>
    </div>
</template>

<script name="productMain">
// @ is an alias to /src

import shBanner from "@/components/sh_banner.vue";
// import pathRoute from "@/components/pathRoute.vue";
import { getProductListAll, getProductTypeList } from '@/api/HomeView/index'

export default {
    name: 'HomeView',
    components: {
        shBanner,
        // pathRoute
    },
    data() {
        return {
            filePathUrl: process.env.VUE_APP_BASE_API,
            routeTest: [
                { title: "网站首页", path: '/' },
                { title: "产品中心", path: '/enterpriseIntroduce/productMain' },
                { title: "营养大健康产品", path: '/enterpriseIntroduce/productMain' },
            ],
            goodsList: [],
            goodsTypeList: [],
            activeIndex: 0,
            queryData: {
                sortType: 1,
                categoryId: ''
            }
        };
    },
    methods: {
        // 获取产品信息
        getViewData() {
            // let data = {
            //     sortType: this.queryData.sortType // 	排序类型 , 1: 推荐 2: 热门 3: 最新
            // }
            getProductListAll(this.queryData).then((res) => {
                this.goodsList = res
            })
        },
        // 获取产品分类信息
        getViewTypeData() {
            getProductTypeList().then((res) => {
                this.goodsTypeList = res
                this.queryData.categoryId = this.goodsTypeList[0]?.id
                // 初始化
                this.getViewData()
            })
        },
        // 跳转详情
        toDetils(item) {
            this.$router.push({
                path: '/productDetails',
                query: { // 路由传参时push和query搭配使用 ，作用时传递参数
                    id: item.id,
                    sortType: this.queryData.sortType,
                    needCategory: true
                }
            })
        },
        // 分类点击
        typeClick(type) {
            let _this = this
            _this.queryData.sortType = type
            _this.getViewData()
        },
        topTypeClick(index, id) {
            this.activeIndex = index
            this.queryData.categoryId = id
            this.getViewData()
        }
    },
    // 初始化载入
    mounted() {
        let _this = this
        _this.getViewTypeData()
    }
}
</script>
<style scoped lang="scss" >
body {
    margin: 0;
    padding: 0;
}

.content-box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

/* 中间内容部分 --start */
/* 产品中心 */
.product {
    width: 1200px;
    background: #F8F8F8;
    border-radius: 0px 0px 0px 0px;
    margin: 0 auto;
    padding-bottom: 80px;
}

.machine-big {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

/* 父级： */
.machine {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

/* 子级： */
.machine-item {
    width: 25%;
    padding-right: 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    cursor: pointer;
}

/* 子级： */
.machine-item:nth-child(4n) {
    padding-right: 0px;
}

.machine-big-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 30px;
}

.machine-big-item {
    width: 48%;
    cursor: pointer;
}

.machine-big-imgs {
    text-align: center;
    background: #D9E1F0;
    border-radius: 9px 9px 0px 0px;
    overflow: hidden;
}

.machine-item:last-child {
    margin-right: 0;
}

.machine-big-item:last-child {
    margin-right: 0;
}

.machine-imgs {
    text-align: center;
    background: #D9E1F0;
    border-radius: 9px 9px 0px 0px;
    /* padding: 35px 0px; */
    overflow: hidden;
}

.machine-big-img {
    width: 100%;
    height: 570px;
    cursor: pointer;
}

.machine-img {
    width: 100%;
    height: 250px;
    cursor: pointer;
}

.machine-big-name {
    width: 100%;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    padding: 30px 0;
    background-color: #fff;
    text-align: center;

}

.machine-name {
    width: 100%;
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    padding: 20px 0;
    background-color: #fff;
    text-align: center;

}

.type-box {
    display: flex;
    align-items: center;
    margin: 50px 0 50px 50px;
}

.type-item {
    display: flex;
    align-items: center;
    margin-right: 30px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
}

.active-type {
    color: #0261B8;
}

/* 中间内容部分 --end */
.allPathRoute {
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin: 0 auto; */
    /* padding-top: 40px; */
    padding: 24px 0;
    /* width: 1920px; */
    /* color: #999; */
    /* height: 0px; */
    box-shadow: 1px 0px 1px 0px rgba(0, 0, 0, 0.6);
    /* opacity: 0.2; */
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);

    .path-route {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        padding-left: 31px;
        // background: url("@/assets/imgs/aboutUs/route.png") no-repeat left center;
        background-size: 16px;
        color: #666;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    }
}

.path-route-line {
    margin: 0 10px;
}

.path-route-rigth {
    margin-right: 70px;
    cursor: pointer;
}
</style>