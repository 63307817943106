<template>
  <div>
    <!-- 顶部部分 包括菜单栏  轮播 面包屑 -->
    <div class="header-box">
      <div class="header-box-top">
        <img class="top-logo" v-if="systemInfo?.officialEmblem" alt="" :src="filePathUrl + systemInfo?.officialEmblem">
        <img class="top-logo" v-else src="@/assets/imgs/sh_logo.png" alt="">
        <div class="top-name">{{ (systemInfo?.officialName) || '贵阳晶戈展珠宝有限公司' }} </div>
      </div>
      <!-- 包括菜单栏 -->
      <div class="menu-box">
        <el-menu class="el-menu-demo" mode="horizontal" text-color="#333333" active-text-color="#0261B8"
          style="display: flex;">
          <template v-for="item in navigationList">

            <el-submenu v-if="item.children && item.children.length > 0" :index="item.path" :key="item.name">
              <template slot="title">
                <span slot="title">{{ item.name }}</span>
              </template>
              <template v-for="(child, cIndex) in item.children">
                <router-link :to="child.path" :key="cIndex">
                  <el-menu-item :index="child.path" @click="routerClick(child.path)"
                    :style="{ backgroundColor:  activeName == child.path ? '#0261B8' : '', color: activeName == child.path ? '#fff' : '#333' }">
                    <span slot="title">{{ child.name }}</span>
                  </el-menu-item>
                </router-link>
              </template>
            </el-submenu>

            <el-menu-item v-else :key="item.name" :index="item.path"><router-link :to="item.path">{{ item.name
            }}</router-link> </el-menu-item>
          </template>
        </el-menu>
        <!-- <div>
					<el-input placeholder="请输入内容" v-model="input3" style="width: 360px;">
						<el-button slot="append" icon="el-icon-search"
							style="background-color: #0261B8;border-radius: 0;border: 1px solid #0261B8;"></el-button>
					</el-input>
				</div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import storage from '@/utils/storage'

export default {
  name: "HelloWorld",
  props: {
    msg: String,
    navigationList: Array,
    systemInfo: Object
  },
  data() {
    return {
      filePathUrl: process.env.VUE_APP_BASE_API,
      // systemInfo: storage.get('systemInfo'), // 系统基本信息
      // navigationList: storage.get('navigationList'), // 系统导航信息
      activeName: ''
    };
  },

  methods: {
    routerClick(name) {
      this.activeName = name
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.el-menu.el-menu--horizontal {
  border-bottom: solid 1px #fff;
}

/* 顶部 --start */
.header-box {
  display: flex;
  flex-direction: column;
}

.header-box-top {
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
}

.top-logo {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.top-name {
  font-size: 26px;
  font-family: Source Han Sans SC-Medium, Source Han Sans SC;
  font-weight: 500;
  color: #000000;
  line-height: 54px;
}

/* 顶部 --end */
/* 菜单 --start */
.menu-box{
  width: 100%;
  background-color: #fff;
  padding: 0px 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* 菜单 --end */
</style>
