<template>
  <div class="allPathRoute">
    <div class="path-route">
      <span class="path-route-rigth" v-for="(item, index) in routeTest" :key="index" @click="activeClick(index)">
        <!-- <a :href="item.path" :style="{ color: activeIndex == index ? '#0261B8' : '' }"> {{ item.title }}</a> -->
        <span :style="{ color: activeIndex == index ? '#0261B8' : '' }"> {{ item.title }}</span>
      </span>
    </div>
  </div>
</template>
<script>
import merge from 'webpack-merge'
export default {
  props: {
    // activeIndex: {
    //   type: Number,
    //   default: 0
    // },
    routeTest: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      activeIndex: 0
    };
  },
  methods: {
    activeClick(index) {
      this.activeIndex = index
      this.$emit('activeClick', { index: index })
      // 更换路由参数
      this.$router.push({
        query: merge(this.$route.query, { 'index': index })
      })

    }
  },
  // 监听路由变化
  watch: {
    $route: {
      handler(newval) {
        this.activeIndex = newval.query.index
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    this.activeIndex = this.$route.query.index
  }
};
</script>
<style lang="scss" scoped>
a {
  color: #666;
}

a:hover {
  color: #0261B8;
}

.allPathRoute {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin: 0 auto; */
  /* padding-top: 40px; */
  padding: 24px 0;
  /* width: 1920px; */
  /* color: #999; */
  /* height: 0px; */
  box-shadow: 1px 0px 1px 0px rgba(0, 0, 0, 0.6);
  /* opacity: 0.2; */
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);

  .path-route {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    padding-left: 31px;
    // background: url("@/assets/imgs/aboutUs/route.png") no-repeat left center;
    background-size: 16px;
    color: #666;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  }
}

.path-route-line {
  margin: 0 10px;
}

.path-route-rigth {
  margin-right: 70px;
  cursor: pointer;
}
</style>
