<template>
    <!-- 晶戈展 新闻动态  -->
    <div class="content-box">
        <!-- 轮播 -->
        <shBanner :pagesName="'newsCode'" :bannerHeight="'476px'"></shBanner>
        <!-- 路径 -->
        <!-- <pathRoute :routeTest="routeTest" /> -->
        <!-- 中间内容部分 -->
        <div class="news">

            <div class="news-content">
                <div class="news-imgs" :style="{ backgroundImage: 'url(' + filePathUrl + newList[0]?.img + ')' }"   @click="toNewsDetails(newList[0], 0)">
                    <div class="news-title"><span>{{ newList[0]?.name }}</span></div>
                </div>

                <div class="news-list">
                    <div class="news-item" v-for="(item, index) in newList" :key="index"
                        @click="toNewsDetails(item, index)">
                        <div class="news-version">
                            <span class="news-version-edit"></span><span class="news-version-name">{{ item.name }}</span>
                        </div>
                        <span class="news-time">{{ item.pubdate }}</span>
                    </div>
                </div>
            </div>
            <div class="news-pagination">
                <pagination v-show="listTotal > 0" :total="listTotal" :page.sync="queryParams.pageNo"
                    :limit.sync="queryParams.pageSize" :pageSizes="pageSizes" @pagination="getViewData" />
            </div>
        </div>

    </div>
</template>

<script>
import shBanner from "@/components/sh_banner.vue";
// import pathRoute from "@/components/pathRoute.vue";
import Pagination from "@/components/Pagination.vue";
import { getNewsPage } from '@/api/HomeView/index'

export default {
    name: 'HomeView',
    components: {
        shBanner,
        // pathRoute,
        Pagination
    },
    data() {
        return {
            filePathUrl: process.env.VUE_APP_BASE_API,
            routeTest: [
                { title: "网站首页", path: '/' },
                { title: "新闻中心", path: '/enterpriseIntroduce/newsMain' },
                { title: "新闻动态", path: '/enterpriseIntroduce/newsMain' },
            ],
            queryParams: {
                pageNo: 1,
                pageSize: 6,
            },
            listTotal: 0,
            pageSizes: [6, 12, 18, 24],
            newList: []
        };
    },
    methods: {
        // 获取新闻信息
        getViewData() {
            let data = {
                pageNo: this.queryParams.pageNo,
                pageSize: this.queryParams.pageSize
            }
            getNewsPage(data).then((res) => {
                this.newList = res.list
                this.listTotal = res.total
            })
        },
        toNewsDetails(item) {
            this.$router.push({
                path: '/newsDetails',
                query: { // 路由传参时push和query搭配使用 ，作用时传递参数
                    id: item.id
                }
            })
        }
    },
    // 初始化载入
    mounted() {
        let _this = this
        // 初始化
        _this.getViewData()

    }
}
</script>
<style scoped>
body {
    margin: 0;
    padding: 0;
}

.content-box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

/* 中间内容部分 --start */
.news {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}


.news-content {
    display: flex;
}

.news-imgs {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    width: 610px;
    height: 400px;
    margin-right: 40px;
}

.news-title {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 46px;
    line-height: 46px;
    padding: 0 25px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-align: left;
}

.news-list {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.news-version-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
}

.news-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
    cursor: pointer;

}

.news-item:last-child {
    margin-bottom: 0;
}

.news-version {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.news-version-edit {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #0261B8;
    margin-right: 30px;
    flex-shrink: 0;
}

.news-time {
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    flex-shrink: 0;
}

.news-pagination {
    margin: 50px 0;
}

/* 中间内容部分 --end */</style>