<template>
  <div id="app">
    <!-- 顶部部分 包括菜单栏  轮播 面包屑 -->
    <shHeader :navigationList="navigationList" :systemInfo="systemInfo"></shHeader>
    <router-view />
    <!-- 通知框 -->
    <shContact></shContact>
    <!-- 底部 -->
    <shFooter></shFooter>
    
    <!-- 回到顶部 -->
    <el-backtop  :bottom="100" :right="60" style="width: 60px;height: 60px;background-color: rgba(225, 225, 225, 0);box-shadow: 0 0 0px rgba(0,0,0,.12);" :visibilityHeight="300">
      <img class="topping" src="@/assets/imgs/sh_zhidingimg.png" />
    </el-backtop>
  </div>
</template>
<script>
// @ is an alias to /src

import shHeader from "@/components/sh_header.vue";
import shFooter from "@/components/sh_footer.vue";
import shContact from "@/components/sh_contact.vue";
import { getCompany,getNavigationList } from '@/api/HomeView/index'
import storage from '@/utils/storage'
export default {
  name: 'HomeView',
  components: {
    shHeader,
    shFooter,
    shContact
  },
  data() {
    return {
      navigationList: [],
      systemInfo: {}
    };
  },
  methods: {
    // 获取系统基本信息
    getData() {
      getCompany().then( (res) => {
        this.systemInfo = res
        storage.set('systemInfo', res)
      })
    },
     // 获取系统导航信息
     getNavigation() {
      getNavigationList().then( (res) => {
        this.navigationList = res
        storage.set('navigationList', res)
      })
    },
  },
  // 初始化载入
  mounted() {
    let _this = this
    // 初始化
    _this.getData()
    _this.getNavigation()
  }

}
</script>
<style>
* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
img{
  max-width: 100%;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* 回到顶部 */
.topping {
  position: fixed;
    right: 60px;
    bottom: 100px;
    width: 60px;
    height: 60px;
    cursor: pointer;
}
</style>
