import http from '@/utils/https'

// 查询公司基础信息详情
export const getCompany = () => {
  return http.get('/company/get')
}

// 查询公司导航信息
export const getNavigationList = () => {
  return http.get('/navigation/get')
}

// 查询公司获得轮播图信息
export const getSlideshow = (code) => {
  return http.get('/slideshow/get?code=' + code)
}

// 查询公司获得首页关于我们信息
export const getAboutme = () => {
  return http.get('/aboutme/get')
}

// 查询公司获得首页-产品中心信息
export const getMainProduct = () => {
  return http.get('/web/product/index/list')
}

// 查询公司获得首页-新闻中心信息
export const getMainnews = () => {
  return http.get('/web/news/index/list')
}

// 首页   创建留言
export const createMessages = (data) => {
  return http.post('/messages/create', data)
}

// 联系我们   招聘中心
export const getRecruitmentPage = (data) => {
  return http.post('/recruitment/page', data)
}
// 新闻中心   分页数据
export const getNewsPage = (data) => {
  return http.post('/web/news/page', data)
}
// 详情-查询上一个-下一个
export const getNewsNext = (data) => {
  return http.post('/web/news/get-pre-next',data)
}


// 新闻中心   详情数据
export const getNews = (id) => {
  return http.get('/web/news/get?id='+id)
}


// 商品   列表数据
export const getProductListAll = (data) => {
  return http.post('/web/product/list-all',data)
}

// 商品    详情数据
export const getProduct = (id) => {
  return http.get('/web/product/get?id='+id)
}
// 商品   列表数据
export const getProductList = (data) => {
  return http.post('/web/product/list',data)
}
// 商品   分类列表数据
export const getProductTypeList = () => {
  return http.post('/web/product/category/list')
}
// 详情-查询上一个-下一个
export const getGoodsNext = (data) => {
  return http.post('/web/product/get-pre-next',data)
}

// 系统   获得内容信息
export const getShContent = (id) => {
  return http.get('/content/get?id='+id)
}

// 系统   获得内容信息
export const getHistory= () => {
  return http.get('/history/page')
}

