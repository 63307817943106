<template>
	<!-- 贵阳晶戈展 首页  -->
	<div class="home">
		<!-- 轮播 -->
		<shBanner :pagesName="'mianCode'" :bannerHeight="'680px'"></shBanner>
		<!-- 中间内容部分 -->
		<!-- 关于我们 -->
		<div class="contact-us">
			<div class="divider-box">
				<div class="divider-title">
					<span class="divider-line"></span>
					<span class="divider-line-title">关于我们</span>
					<span class="divider-line"></span>
				</div>
				<div class="divider-english">Our strengths</div>
			</div>
			<div class="history animate__animated animate__animated animate__fadeInRight">
				<div class="history-list" :style="{ backgroundImage: 'url(' + filePathUrl + aboutmeList.icon + ')' }">
					<template v-for="(item, index) in aboutmeList.child">
						<a :href="item.pageUrl" class="history-item hvr-sweep-to-top" :key="index"
							@mousemove="hoveMove(index)" @mouseleave="hoveOut()">
							<img class="history-icon" :style="{ marginTop: hoveIndex == index ? '50px' : '' }"
								:src="filePathUrl + item.icon">
							<span class="history-title">{{ item?.name }}</span>
							<div class="history-content">{{ item?.aboutProfile }}</div> 
							<div class="see-box" v-if="hoveIndex == index">
								查看更多
								<img class="more-img" src="@/assets/imgs/sh_rightwiter.png" />
							</div>
						</a>
					</template>
				</div>
			</div>
		</div>

		<!-- 产品中心 -->
		<div style="background-color: #F8F8F8;">
			<div class="product">
				<div class="divider-box">
					<div class="divider-title">
						<span class="divider-line"></span>
						<span class="divider-line-title">产品中心</span>
						<span class="divider-line"></span>
					</div>
					<div class="divider-english">product</div>
				</div>
				<div class="material">
					<template v-for="(item, index) in goodsList">
						<div class="material-item animate__animated animate__fadeInLeft" :key="index"
							@click="toGoodesDetils(item)" v-if="index == 0 || index == 1">
							<div class="material-item-top">
								<div class="material-item-img-box">
									<img class="material-item-img hvr-grow" :src="filePathUrl + item.img" />
								</div>
								<div class="material-item-introduce">
									<span class="introduce-item-name">{{ item.name }}</span>
									<div class="introduce-item-profile">
										{{ item.intro }}
									</div>
								</div>
							</div>
							<div class="profile-content">
								{{ item.intro }}
							</div>
						</div>
					</template>
				</div>

				<div class="machine">
					<template v-for="(item, index) in goodsList">
						<div class="machine-item animate__animated animate__fadeInLeft" :key="index"
							v-if="index >= 2 && index <= 5" @click="toGoodesDetils(item)">
							<div class="machine-imgs">
								<img class="machine-img hvr-grow" :src="filePathUrl + item.img" />
							</div>
							<div class="machine-name">
								{{ item.name }}
							</div>
						</div>
					</template>
				</div>
				<a href="/productMain">
					<div class="more-see hvr-wobble-horizontal">
						<span class="more-title">查看更多</span>
						<img class="more-img" src="@/assets/imgs/ckgdright.png" />
					</div>
				</a>

			</div>
		</div>

		<div class="aboutandnews">
			<!-- 企业简介 -->
			<div class="about">
				<div class="divider-box">
					<div class="divider-title">
						<span class="divider-line"></span>
						<span class="divider-line-title">企业简介</span>
						<span class="divider-line"></span>
					</div>
					<div class="divider-english">about</div>
				</div>
				<div class="about-box">
					<img class="about-img animate__animated animate__fadeInLeft" src="@/assets/imgs/shgsjjdt.png" />
					<div class="about-right animate__animated animate__fadeInRight">
						<div class="about-content">
							{{systemInfo?.companyProfile}}
						</div>
						<!-- <a href="/enterpriseIntroduce">
							<div class="about-more">
								了解更多>>
							</div>
						</a> -->
						<a href="/aboutUs?index=0" class="hvr-wobble-horizontal">
							<div class="about-more-btn">了解更多>></div>
						</a>
					</div>
				</div>
			</div>
			<!-- 新闻中心 -->
			<div class="news">
				<div class="divider-box">
					<div class="divider-title">
						<span class="divider-line"></span>
						<span class="divider-line-title">新闻中心</span>
						<span class="divider-line"></span>
					</div>
					<div class="divider-english">News </div>
				</div>
				<div class="news-box">
					<div class="news-list">
						<template v-for="(item, index) in newsList">
							<div class="news-item animate__animated animate__fadeInLeft" :key="index" @click="toNewsDetails(item)"
								v-if="index == 0 || index == 1">
								<div class="news-item-imgs">
									<img class="news-item-img hvr-grow" :src="filePathUrl + item.img" />
								</div>
								<div class="news-item-rigth">
									<div class="news-item-title">{{ item.name }}</div>
									<div class="news-item-content">
										{{ item.intro }}
									</div>
									<div class="news-item-footer" >
										<span class="news-item-time">{{ item.pubdate }}</span>
										<span class="news-item-see">查看详情>></span>
									</div>
								</div>
							</div>
						</template>
					</div>
					<template v-for="(item, index) in newsList">
						<div class="news-plan animate__animated animate__fadeInRight" :key="index" v-if="index == 2" @click="toNewsDetails(item)">
							<div class="news-plan-imgs">
								<img class="news-plan-img hvr-grow" :src="filePathUrl + item.img" />
							</div>
							<div class="news-plan-bottom">
								<div class="news-plan-title">
									{{ item.name }}
								</div>
								<div class="news-plant-content">
									{{ item.intro }}
								</div>
								<div class="news-plant-footer" >
									<span class="news-item-time">{{ item.pubdate }}</span>
									<span class="news-item-see">查看详情>></span>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<!-- 联系我们 -->
		<div style="background-color: #F8F8F8;padding-bottom: 30px;">
			<div class="connectus">
				<div class="divider-box">
					<div class="divider-title">
						<span class="divider-line"></span>
						<span class="divider-line-title">联系我们</span>
						<span class="divider-line"></span>
					</div>
					<div class="divider-english">CONNECT US </div>
				</div>
				<div class="connectus-title">为客户更好的服务，成就客户，成就自我</div>
				<div class="connectus-input-box">
					<el-form :model="formData" :rules="rules" ref="ruleForm" style="width: 100%" :inline="true">
						<div class="demo-ruleForm">
							<el-form-item label="" prop="name">
								<el-input placeholder="姓名" v-model="formData.name" style="width: 360px;" />
							</el-form-item>
							<el-form-item label="" prop="tel">
								<el-input placeholder="电话号码" v-model="formData.tel" style="width: 360px;" />
							</el-form-item>
							<el-form-item label="" prop="email">
								<el-input placeholder="联系邮箱" v-model="formData.email" style="width: 360px;" />
							</el-form-item>
						</div>
						<el-form-item label="" prop="message" class="form-item">
							<el-input type="textarea" :rows="12" placeholder="留言内容" style="width: 100%;"
								v-model="formData.message" />
						</el-form-item>

					</el-form>
				</div>
				<button class="connectus-submit" @click="submitMsg">提交留言</button>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

import shBanner from "@/components/sh_banner.vue";
import storage from '@/utils/storage'
import { getAboutme, getMainProduct, getMainnews, createMessages, getCompany } from '@/api/HomeView/index'
import { Message } from 'element-ui'

export default {
	name: 'HomeView',
	components: {
		shBanner,
	},
	data() {
		// 验证手机号的正则表达式
		var checkMobile = (rule, value, cb) => {
			const regMobile = /^[1][1,2,3,4,5,7,8,9][0-9]{9}$/;
			if (regMobile.test(value)) {
				return cb();
			}
			cb(new Error("请输入正确的手机号码"));
		};
		/**
		 * 验证电子邮箱格式
		 */
		var regEmail = (rule, value, cb) => {
			// 验证手机号的正则表达式
			const regMobile = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
			if (value) {
				if (regMobile.test(value)) {
					return cb();
				}
				cb(new Error("请输入正确的邮箱地址"));
			}else{
				return cb();
			}

		};
		return {
			formData: {
				name: '',
				tel: '',
				email: '',
				message: '',
			},
			rules: {
				name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
				tel: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{
						validator: checkMobile,
						message: "请输入正确的手机号码",
						trigger: "blur"
					}],
				email: [
				{
					validator: regEmail,
					message: "请输入正确的邮箱地址",
					trigger: "blur"
				}],
				message: [{ required: true, message: '请输入留言内容', trigger: 'blur' }],
			},
			filePathUrl: process.env.VUE_APP_BASE_API,
			systemInfo: storage.get('systemInfo'), // 系统基本信息
			aboutmeList: [],//  首页关于我们信息
			goodsList: [],//  首页产品信息
			newsList: [],//  首页新闻信息
			hoveShow: false,
			hoveIndex: -1,

		};
	},
	methods: {
		// 获取首页关于我们信息
		getAboutmeData() {
			getAboutme().then((res) => {
				this.aboutmeList = res
				// console.log('关于我们信息=========>', this.aboutmeList)
			})
		},
		// 获取首页产品信息
		getproductData() {
			getMainProduct().then((res) => {
				this.goodsList = res
				// console.log('产品信息=========>', this.goodsList)
			})
		},
		// 获取首页新闻信息
		getnewsData() {
			getMainnews().then((res) => {
				this.newsList = res
				// console.log('新闻信息=========>', this.newsList)
			})
		},
		// 跳转新闻详情
		toNewsDetails(item) {
			this.$router.push({
				path: '/newsDetails',
				query: { // 路由传参时push和query搭配使用 ，作用时传递参数
					id: item.id
				}
			})
		},
		// 跳转产品详情
		// 跳转详情
		toGoodesDetils(item) {
			this.$router.push({
				path: '/productDetails',
				query: { // 路由传参时push和query搭配使用 ，作用时传递参数
					id: item.id,
				}
			})
		},
		// 移动上去移动事件
		hoveMove(index) {
			this.hoveIndex = index
		},
		// 移出
		hoveOut(){
			this.hoveIndex = -1
		},
		// 提交留言
		submitMsg() {
			this.$refs['ruleForm'].validate((valid) => {
				if (valid) {
					createMessages(this.formData).then(() => {
						Message.success({
							message: '提交成功,稍后会有专人与您联系'
						})
						this.formData = {
							name: '',
							tel: '',
							email: '',
							message: '',
						}
					})
				}
			})
		},
		getSystemInfo() {
			getCompany().then( (res) => {
				this.systemInfo = res
			})
		}

	},
	// 初始化载入
	mounted() {
		let _this = this
		// 初始化
		_this.getAboutmeData()
		_this.getproductData()
		_this.getnewsData()
		this.getSystemInfo()

	}
}
</script>
<style>
/* form */
.demo-ruleForm {
	display: flex;
	justify-content: space-between;
}

.el-form--inline .el-form-item {
	margin-right: 0px !important;
}

.el-form--inline .form-item {
	width: 100%;
}

.el-form--inline .form-item .el-form-item__content {
	width: 100% !important;
}
</style>
<style scoped>
body {
	margin: 0;
	padding: 0;
}

.home {
	display: flex;
	flex-direction: column;
	background-color: #fff;
}

/* 中间内容部分 --start */


/* 联系我们 */
.contact-us {
	width: 100%;
	background-color: #fff;
	padding-bottom: 70px;
	box-sizing: border-box;
}

.divider-box {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-top: 40px;
}

.divider-title {
	display: flex;
	align-items: center;
}

.divider-line {
	width: 30px;
	height: 3px;
	background-color: #333333;
}

.divider-line-title {
	font-size: 32px;
	font-family: Microsoft YaHei-Bold, Microsoft YaHei;
	font-weight: bold;
	color: #333333;
	margin: 0 10px;
}

.divider-english {
	font-size: 16px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #999999;
	margin-top: 5px;
}

.history {
	width: 100%;
	margin-top: 50px;
	display: flex;
	justify-content: center;
}

.history-list {
	width: 1200px;
	height: 450px;
	background-image: url('@/assets/imgs/hosty_baimg.png');
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
}

.history-item {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-right: 3px solid #999999;
	height: 100%;
	padding: 0 30px;
	box-sizing: border-box;
	cursor: pointer;
}

.history-item:last-child {
	border-right: 0px solid #fff;
}

.history-icon {
	width: 30px;
	height: 30px;
	margin-top: 125px;
}

.history-title {
	font-size: 20px;
	color: #FFFFFF;
	margin-top: 50px;
}

.history-content {
	font-size: 12px;
	font-family: Microsoft YaHei-Bold, Microsoft YaHei;
	color: #FFFFFF;
	margin-top: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

/* 产品中心 */
.product {
	width: 1200px;
	background: #F8F8F8;
	border-radius: 0px 0px 0px 0px;
	margin: 0 auto;
}

.material {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
}

.material-item {
	display: flex;
	flex-direction: column;
	/* height: 320px; */
	background: #FFFFFF;
	padding: 30px 30px 0 30px;
	box-sizing: border-box;
	margin-right: 25px;
	height: 320px;
	flex: 1;
	cursor: pointer;
}

.material-item:last-child {
	margin-right: 0;
}

.material-item-top {
	display: flex;
	/* width: 564px; */
	border-bottom: 2px solid hsl(0, 0%, 96%);
	padding-bottom: 30px;
	box-sizing: border-box;
}

.material-item-img-box {
	display: block;
	width: 158px;
	height: 158px;
	margin-right: 25px;
	cursor: pointer;
	overflow: hidden;
	flex-shrink: 0;
}

.material-item-img {
	width: 100%;
	height: 100%;
}

.material-item-introduce {
	display: flex;
	flex-direction: column;
}

.introduce-item-name {
	font-size: 20px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #333333;
	margin-bottom: 15px;
	text-align: left;
	margin-top: 20px;
}

.introduce-item-profile {
	font-size: 12px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #333333;
	line-height: 24px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.profile-content {
	margin-top: 20px;
	font-size: 14px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #333333;
	line-height: 24px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.machine {
	display: flex;
	align-items: center;
	margin-top: 30px;
}

.machine-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #FFFFFF;
	border-radius: 10px 10px 10px 10px;
	border: 1px solid #F4F4F4;
	width: 24%;
	margin-right: 30px;
	cursor: pointer;
	font-size: 18px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	text-align: center;
	font-weight: 400;
	color: #333333;
}

.machine-item:last-child {
	margin-right: 0;
}
.machine-item:hover{
	color: #fff;
	background-color: #0261B8;
}

.machine-imgs {
	text-align: center;
	background: #D9E1F0;
	border-radius: 9px 9px 0px 0px;
	overflow: hidden;
}

.machine-img {
	width: 100%;
	height: 250px;
	cursor: pointer;
}

.machine-name {
	width: 100%;
	padding: 20px 0;
	box-sizing: border-box;
}

/* 查看更多 */
.more-see {
	width: 180px;
	height: 50px;
	line-height: 50px;
	background: #FFFFFF;
	border-radius: 4px 4px 4px 4px;
	border: 1px solid #707070;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	margin-top: 50px;
	cursor: pointer;
	margin-bottom: 90px;
}

.more-title {
	font-size: 16px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #666666;
	margin-right: 8px;
}

.more-img {
	width: 8px;
	height: 14px;

}

/* 产品简介 */
.about {
	width: 1200px;
	margin: 0 auto;
	margin-bottom: 130px;
}

.about-box {
	display: flex;
	align-items: center;
	margin-top: 40px
}

.about-img {
	width: 657px;
	/* height: 550px; */
	margin-right: 60px;
}

.about-right {
	display: flex;
	flex-direction: column;
}

.about-content {
	font-size: 16px;
	font-family: Source Han Sans CN-Medium, Source Han Sans CN;
	font-weight: 500;
	color: #666666;
	line-height: 24px;
	text-align: left;
}

.about-more {
	font-size: 14px;
	font-family: Source Han Sans CN-Regular, Source Han Sans CN;
	font-weight: 400;
	color: #F7A444;
	margin-top: 45px;
	text-align: left;
	cursor: pointer;
}

.about-more-btn {
	width: 108px;
	height: 40px;
	line-height: 40px;
	background: #F7A444;
	border-radius: 4px 4px 4px 4px;
	font-size: 14px;
	font-family: Source Han Sans CN-Regular, Source Han Sans CN;
	font-weight: 400;
	color: #FFFFFF;
	margin-top: 30px;
	cursor: pointer;
	text-align: center;
}

/*  新闻中心 */
.news {
	width: 1200px;
	margin: 0 auto;
}

.news-box {
	display: flex;
	align-items: center;
	margin-top: 150px;
}

.news-list {
	display: flex;
	flex-direction: column;
	margin-right: 120px;
}

.news-item {
	display: flex;
	/* align-items: center; */
	margin-bottom: 60px;
	cursor: pointer;
}

.news-item:last-child {
	margin-bottom: 0;

}

.news-item-imgs {
	width: 240px;
	height: 170px;
	border-radius: 20px 20px 20px 20px;
	margin-right: 17px;
	flex-shrink: 0;
	overflow: hidden;
	cursor: pointer;
}

.news-item-img {
	width: 100%;
	height: 100%;
}

.news-item-rigth {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.news-item-title {
	font-size: 20px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #333333;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.news-item-content {
	font-size: 14px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #999999;
	line-height: 24px;
	margin-top: 20px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;

}

.news-item-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	cursor: pointer;
}

.news-item-time {
	font-size: 14px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #999999;
	flex-shrink: 0;
}

.news-item-see {
	font-size: 14px;
	font-family: Source Han Sans CN-Regular, Source Han Sans CN;
	font-weight: 400;
	color: #0261B8;
	flex-shrink: 0;

}

.news-plan {
	display: flex;
	flex-direction: column;
	background: #F8F8F8;
	border-radius: 20px 20px 20px 20px;
	width: 480px;
}

.news-plan-imgs {
	width: 480px;
	height: 210px;
	border-radius: 20px 20px 0px 0px;
	overflow: hidden;
	flex-shrink: 0;
}

.news-plan-img {
	width: 100%;
	height: 100%;
}

.news-plan-bottom {
	padding: 0 20px;
	box-sizing: border-box;
}

.news-plan-title {
	font-size: 24px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #333333;
	margin: 20px 0;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.news-plant-content {
	font-size: 14px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #999999;
	line-height: 24px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.news-plant-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	padding-bottom: 30px;
	cursor: pointer;
	box-sizing: border-box;
}

.news-plant-see {
	font-size: 14px;
	font-family: Source Han Sans CN-Regular, Source Han Sans CN;
	font-weight: 400;
	color: #0261B8;
}

.news-plant-time {
	font-size: 14px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #999999;
}

.aboutandnews {
	/* background-color: #fff; */
	padding-bottom: 130px;
	box-sizing: border-box;
}

/* 联系我们 */
.connectus {
	width: 1200px;
	background-size: contain;
	background-position: bottom;
	background-repeat: no-repeat;
	margin: 0 auto;
	padding: 50px 120px;
}

.connectus-title {
	font-size: 18px;
	font-family: PingFang SC-Medium, PingFang SC;
	font-weight: 500;
	color: #333333;
	margin-top: 20px;
	text-align: center;
}

.connectus-input-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 80px 0 30px 0;
}

.el-form-item__content {
	width: 100%;
}

.connectus-submit {
	width: 100%;
	height: 60px;
	line-height: 60px;
	text-align: center;
	background: #0261B8;
	border-radius: 5px 5px 5px 5px;
	margin-top: 30px;
	font-size: 18px;
	font-family: PingFang SC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	cursor: pointer;
	border: none;
}

/* 中间内容部分 --end */

.el-carousel__item h3 {
	color: #475669;
	font-size: 18px;
	opacity: 0.75;
	line-height: 300px;
	margin: 0;
}

.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
	background-color: #d3dce6;
}

.hvr-sweep-to-topshow {
	background: #3495FF;
	opacity: 0.7;
}

.hvr-sweep-to-top:before {
	background: rgb(2, 97, 184, 0.7);
}

.see-box {
	width: 140px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #FFFFFF;
	font-size: 16px;
	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	font-weight: 400;
	color: #FFFFFF;
	margin-top: 30px;
}
</style>