<template>
    <!-- 晶戈展 新闻动态  -->
    <div class="content-box">
        <!-- 轮播 -->
        <shBanner :pagesName="'newsCode'" :bannerHeight="'476px'"></shBanner>
        <!-- 路径 -->
        <!-- <pathRoute :routeTest="routeTest" /> -->
        <!-- 中间内容部分 -->
        <div class="news">
            <span class="news-title">
                {{ detailsInfo.name }}
            </span>
            <div class="news-top">
                <span class="news-time">
                    {{ detailsInfo.pubdateStr }}
                </span>
                <div class="news-see">
                    <img class="news-see-icon" src="@/assets/imgs/shviews.png" />
                    <span class="news-see-num">
                        {{ detailsInfo.view }}
                    </span>
                </div>
            </div>
            <!-- 新闻内容 -->
            <div v-html="detailsInfo.content" class="news-content"></div>
            <div class="news-operate">
                <el-tooltip :content="(nextObj.pre?.name) ? nextObj.pre?.name :'暂无数据'" placement="top" effect="light">
                    <div class="operate-item" @click="upClick(nextObj.pre?.id)">上一个：{{ (nextObj.pre?.name) ?
                        nextObj.pre?.name :
                        '暂无数据' }}
                    </div>
                </el-tooltip>
                 <el-tooltip :content="(nextObj.next?.name) ? nextObj.next?.name :'暂无数据'" placement="top" effect="light">
                    <div class="operate-item" @click="downClick(nextObj.next?.id)">下一个：{{ (nextObj.next?.name) ?
                        nextObj.next?.name :
                        '暂无数据' }}
                    </div>
                </el-tooltip>
            </div>
        </div>

    </div>
</template>

<script>
import shBanner from "@/components/sh_banner.vue";
// import pathRoute from "@/components/pathRoute.vue";
import { getNewsNext, getNews } from '@/api/HomeView/index'

export default {
    name: 'newsView',
    components: {
        shBanner,
        // pathRoute
    },
    data() {
        return {
            routeTest: [
                { title: "网站首页", path: '/' },
                { title: "新闻中心", path: '/enterpriseIntroduce/newsMain' },
                { title: "新闻动态", path: '/enterpriseIntroduce/newsMain' },
            ],
            detailsInfo: {},
            datailsId: '',
            listTotal: 0,
            nextObj: {}
        };
    },
    methods: {
        // 获取新闻详情信息
        getViewDetailsData() {
            getNews(this.datailsId).then((res) => {
                this.detailsInfo = res
            })
        },
        // 获取 详情-查询上一个-下一个新闻信息
        getViewData() {
            let data = {
                id: this.datailsId
            }
            getNewsNext(data).then((res) => {
                this.nextObj = res
                this.getViewDetailsData()
            })
        },
        // 上一个
        upClick(id) {
            let _this = this
            if (id) {
                this.datailsId = id
                _this.getViewData()
            } else {
                this.$message.warning('无更多新闻')
            }
        },
        // 下一个
        downClick(id) {
            let _this = this
            if (id) {
                this.datailsId = id
                _this.getViewData()
            } else {
                this.$message.warning('无更多新闻')
            }
        }
    },
    // 初始化载入
    mounted() {
        let _this = this
        // 初始化
        _this.datailsId = this.$route.query.id
        _this.getViewData()
    }
}
</script>
<style scoped>
body {
    margin: 0;
    padding: 0;
}

.content-box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-bottom: 52px;
}

/* 中间内容部分 --start */
.news {
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
}

.news-title {
    display: block;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
}

.news-top {
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #F4F4F4;
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #999999;
}

.news-see {
    display: flex;
    align-items: center;
}

.news-see-icon {
    width: 21px;
    height: 13px;
    margin-right: 5px;
}

.news-content {
    padding: 60px 0;
}
.news-content img{
    max-width: 100%;
}
.news-operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #F4F4F4;
    padding-top: 40px;
}

.operate-item {
    width: 310px;
    height: 40px;
    line-height: 40px;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* margin-right: 290px; */
    text-align: left;
    padding: 0 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.operate-item:hover {
    color: #0261B8;
  border: 1px solid #0261B8;

}

/* 中间内容部分 --end */
</style>